import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  fs14: {
    fontSize: "14px",
  },
}));

const Error = ({ onAction, isBetWarning, message }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div
      className={`menu menu-box-modal ${
        isBetWarning === "show" ? "menu-active" : ""
      } bg-red-dark rounded-m`}
      data-menu-height={310}
      data-menu-width={350}
    >
      <h1 className="text-center mt-4">
        <i className="fa fa-3x fa-times-circle scale-box color-white shadow-xl rounded-circle" />
      </h1>
      <h1
        className={`text-center mt-3 text-uppercase color-white ${classes.fs14} font-700`}
      >
        {message}
      </h1>
      <button
        onClick={() => {
          onAction();
        }}
        className="btn btn-m btn-center-l button-s shadow-l rounded-s text-uppercase font-600 bg-white color-black"
      >
        {t("OK")}
      </button>
    </div>
  );
};

export default Error;
