import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//import PropTypes from "prop-types";
import axiosInstance from "../../config";
import "../../styles/MobileMain.css";
import { useHistory } from "react-router-dom";
import qs from "qs";

const ChangePassword = ({ User }) => {
  let history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const changePassword = () => {
    let currentPass = document.getElementById("currentPass").value;
    let newPass = document.getElementById("newPass").value;
    let confirmPass = document.getElementById("confirmPass").value;

    var letter = /[a-zA-Z]/;
    var number = /[0-9]/;
    const result = letter.test(newPass) && number.test(newPass);

    if (!result) {
      setErrorMessage(t("Password should be alphanumeric"));
      window.$("#toastError").toast("show");
    } else if (currentPass === "") {
      setErrorMessage(t("Current password cannot be empty"));
      window.$("#toastError").toast("show");
    } else if (newPass === "") {
      setErrorMessage(t("New password cannot be empty"));
      window.$("#toastError").toast("show");
    } else if (confirmPass === "") {
      setErrorMessage(t("Confirm password cannot be empty"));
      window.$("#toastError").toast("show");
    } else if (confirmPass !== newPass) {
      setErrorMessage(t("Confirm password must be same with new password"));
      window.$("#toastError").toast("show");
    } else {
      setLoading(true);
      axiosInstance
        .post(
          "portal/password",
          qs.stringify({
            current: currentPass,
            newpwd: newPass,
          })
        )
        .then((res) => {
          window.$("#toastSuccess").toast("show");
        })
        .catch((err) => {
          if (err.response) {
            setErrorMessage(err.response.data.message);
          } else {
            setErrorMessage(err.toString());
          }
          window.$("#toastError").toast("show");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">{t("Change Password")}</span>

        <span
          className="header-icon header-icon-1"
          onClick={() => {
            history.goBack();
          }}
        >
          <i className="fas color-white fa-arrow-left color-red-light" />
        </span>
      </div>
      <div className="page-title-clear" />
      <div className="page-content">
        <div className="card card-style mt-3 rounded">
          <div className="content" id="form-password">
            <div className="input-style input-style-1 input-required">
              <span className="color-highlight input-style-1-inactive">
                {t("Current Password")}
              </span>
              <em>({t("required")})</em>
              <input
                type="password"
                placeholder={t("Current Password")}
                id="currentPass"
              />
            </div>
            <div className="input-style input-style-1 input-required">
              <span className="color-highlight input-style-1-inactive">
                {t("New Password")}
              </span>
              <em>({t("required")})</em>
              <input
                type="password"
                placeholder={t("New Password")}
                id="newPass"
              />
            </div>
            <div className="input-style input-style-1 input-required">
              <span className="color-highlight input-style-1-inactive">
                {t("Confirm Password")}
              </span>
              <em>({t("required")})</em>
              <input
                type="password"
                placeholder={t("Confirm Password")}
                id="confirmPass"
              />
            </div>
          </div>
          {loading ? (
            <div className="text-center" style={{ marginTop: 16 }}>
              <div className="spinner-border color-red-dark" role="status">
                <span className="sr-only">{t("Loading")}...</span>
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                changePassword();
              }}
              className="btn btn-s btn-full mb-3 ml-3 mr-3 rounded font-600 btn-green"
            >
              {t("Submit")}
            </div>
          )}
        </div>
      </div>
      {/* Page content ends here*/}
      <div
        id="toastError"
        className="toast toast-tiny toast-bottom bg-red-dark fade hide toast-custom"
        data-delay="3000"
        data-autohide="true"
      >
        <i className="fa fa-times mr-3" />
        {errorMessage}
      </div>
      <div
        id="toastSuccess"
        className="toast toast-tiny toast-bottom bg-green-dark fade hide toast-custom"
        data-delay="3000"
        data-autohide="true"
      >
        <i className="fa fa-times mr-3" />
        {t("Change password succesfully")}
      </div>
    </>
  );
};

export default ChangePassword;
