import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px 5px rgba(0, 0, 0, 0.03)',
    borderRadius: '4px',
  },
  cardHeader: {
    background: '#86898C',
    borderRadius: '4px 4px 0 0',
  },
  spacing: {
    padding: '0.15rem 0.75rem',
    borderBottom: '1px solid rgba(134, 137, 140, 0.7)',
    '& p': {
      margin: '0 ',
    },
  },
  borderDiv: {
    border: '0.5px solid #86898C',
  },
  cardBody: {
    boxShadow: '0px 2px 8px rgba(81, 81, 81, 0.4)',
    borderRadius: '0 0 4px 4px',
  },
  viewBtn: {
    border: '1px solid #86898C',
    color: '#86898C',
    backgroundColor: 'transparent',
  },
}));

const LegacyHistory = ({
  legacyHistory,
  setLegacyHistory,
  pageLegacy,
  totalPageLegacy,
  loadLegacyHistory,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      {legacyHistory !== undefined ? (
        <>
          {legacyHistory.map((htry, index) => (
            <React.Fragment key={index}>
              <div
                className={`${classes.card}`}
                onClick={() => {
                  let path = '/mobile/me/legacy/history/' + htry.date;
                  history.push(path);
                }}
              >
                <div className={`${classes.spacing}`}>
                  <div className="d-flex list-group list-custom-small list-menu ml-0 mr-2">
                    <strong>
                      {htry.date.replace('-', '/').replace('-', '/')}
                    </strong>
                    <span className="badge bg-blue">{htry.ct}&nbsp;&gt;</span>
                  </div>
                  <table className="list-table-detail mt-1">
                    <tbody>
                      <tr>
                        <td className="list-table-detail-title">{t("Amount")}</td>
                        <td
                          // style={{ color: '#000000' }}
                          className="cash-value"
                        >
                          {htry.amount != null ? (
                            <CurrencyFormat
                              value={htry.amount}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              className={`text-primary font-weight-bold`}
                            />
                          ) : (
                            <span className={`text-primary font-weight-bold`}>
                              0.00
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="list-table-detail-title">{t("Winning")}</td>
                        <td
                          // style={{ color: '#1553cc' }}
                          className="cash-value"
                        >
                          {htry.winning != null ? (
                            <CurrencyFormat
                              value={htry.winning}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              className={
                                htry.winning < 0
                                  ? `text-danger font-weight-bold`
                                  : `text-primary font-weight-bold`
                              }
                            />
                          ) : (
                            <span className={`text-primary font-weight-bold`}>
                              0.00
                            </span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </React.Fragment>
          ))}
          {legacyHistory.length === 0 ? (
            <p className="text-center my-3">{t("no result found")}</p>
          ) : null}
        </>
      ) : null}
      {pageLegacy + 1 < totalPageLegacy ? (
        <>
          <div className="card">
            <button
              className="btn btn-m btn-full mb-3 ml-3 mr-3 rounded-xs btn-green"
              onClick={() => {
                loadLegacyHistory(pageLegacy + 1);
              }}
            >
              {t("Load More ...")}
            </button>
            <p />
          </div>
        </>
      ) : null}
    </>
  );
};

export default LegacyHistory;
