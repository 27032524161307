import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axiosInstance2 from '../config2';
const Maintenance = () => {
  const { t } = useTranslation();
  useEffect(() => {
    axiosInstance2
      .get('portal/isMaintenanceActive')
      .then((res) => {
        // console.log(res);
        if (res.data === false) {
          window.location.href = '/odds';
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/';
        }
      });
  }, []);

  return (
    <>
      <div className="container d-flex flex-column justify-content-center align-items-center text-center vh-100">
        <img
          src={require('../img/maintenance.png')}
          alt=""
          className="w-50 mb-5"
        />
        <h2 className="text-body font-weight-bold">
        {t("TEMPORARILY UNDER MAINTENANCE")}
        </h2>
        <p className="text-body mt-1 ">
        {t("Our website is currently undergoing scheduled maintenance, we will be back soon. Thank you for your patience.")}
        </p>
      </div>
    </>
  );
};

export default Maintenance;
