export default function serviceWorkerDev() {
  let swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`;
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(swUrl)
      .then((res) => {
        console.warn('registered', res);
      })
      .catch((err) => console.log('serviceWorker not registered', err));
  }
}
