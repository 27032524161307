import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import qs from "qs";
import "../styles/LeagueFilter.css";
import axiosInstance from "../config";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  successbutton: {
    width: "109px",
    height: "34px",
    color: "#fff",
    border: "none",
    marginRight: "5px",
    boxSizing: "border-box",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "6px",
    backgroundColor: "rgb(78, 173, 38)",
    "&:hover": {
      background: "rgb(78, 173, 38, 0.6)"
    }
  },
  cancelbutton: {
    width: "109px",
    height: "34px",
    color: "#fff",
    border: "none",
    boxSizing: "border-box",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "6px",
    backgroundColor: "rgb(237, 146, 151)",
    "&:hover": {
      background: "rgb(237, 146, 151, 0.6)"
    }
  },
  checkboxCenter: {
    display: "flex !important",
    alignItems: "center",
    height: "32px"
  }
}));

const LeagueFilter = ({
  openLeagueFilter,
  setOpenLeagueFilter,
  trigger,
  getSelectedMenu
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [league, setLeague] = useState([]);
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  window.HTMLElement.prototype.scrollIntoView = function() {};

  const changeAll = a => {
    setLeague(l =>
      l.map(s => {
        s.active = a;
        return s;
      })
    );
  };

  const handleSearchText = event => {
    setSearchText(event.target.value.toLowerCase());
  };

  const filterLeague = () => {
    setOpenLeagueFilter(false);
    let selectedLeagues = league.filter(l => !l.active);
    let market = "R";
    if (getSelectedMenu("Odds") === "Early") {
      market = "E";
    } else if (getSelectedMenu("Odds") === "Today") {
      market = "T";
    }

    let url = "restricted/leagues";
    let compIds = '';
    if (selectedLeagues.length > 0) {
      selectedLeagues.forEach(l => {
        compIds += l.key +",";
      });
    }

    let requestBody = {
      market: market,
      compIds: compIds.length > 0 ? compIds.slice(0,-1) : '',
    };

    return axiosInstance
      .post(url, qs.stringify(requestBody))
      .then(() => {
        trigger();
      })
      .catch(err => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push("/");
        }
      });
  };

  const getSearchLeague = () => {
    return league.filter(l => {
      if (searchText) {
        return l.val.toLowerCase().indexOf(searchText) !== -1;
      } else {
        return true;
      }
    });
  };

  useEffect(
    () => {
      let market = "R";
      if (getSelectedMenu("Odds") === "Early") {
        market = "E";
      } else if (getSelectedMenu("Odds") === "Today") {
        market = "T";
      }
      var url = "restricted/leagues?market=" + market;
      if (getSelectedMenu("Game") === "Basketball") {
        url += "&s=2";
      }

      axiosInstance
        .get(url)
        .then(res => {
          setLeague(res.data);
        })
        .catch(err => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            localStorage.removeItem('token');
            history.push("/");
          }
        });
    },
    [history]
  );

  return (
    <>
      <div className="header header-logo-center mb-4 result-drawer">
        <p className="header-title">{t("Select League")}</p>
        <button
          onClick={() => filterLeague()}
          className="header-icon header-icon-1 d-flex align-items-center"
        >
          <span className="mr-1">{t("Done")}</span>
          <i className="fas fa-check" />
        </button>
        <button
          onClick={() => setOpenLeagueFilter(false)}
          className="header-icon header-icon-4"
        >
          <i className="fas fa-chevron-right" />
        </button>
      </div>

      <div className="header-fixed m-3">
        <div className="input-style input-style-2 has-icon input-required">
          <i className="input-icon fa fa-search" />
          <span className="color-highlight input-style-1-inactive">{t("Search")}</span>
          <input
            className="form-control"
            onChange={handleSearchText}
            type="name"
            placeholder=""
          />
        </div>
      </div>

      <div className="header-fixed m-3">
        <div className="row">
          <div className="col-6">
            <button
              onClick={() => changeAll(true)}
              className="btn btn-block btn-primary"
            >
              <i className="fa fa-check-square" /> {t("Select All")}
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => changeAll(false)}
              className="btn btn-block btn-secondary"
            >
              <i className="fa fa-minus-square" /> {t("Unselect All")}
            </button>
          </div>
        </div>
      </div>

      <div className="card mt-3 mx-2">
        {getSearchLeague().map((option, index) => (
          <React.Fragment key={index}>
            <div className={`custom-control ios-switch highlight-switch py-2`}>
              <input
                type="checkbox"
                checked={option.active}
                onChange={() =>
                  option.active
                    ? (option.active = false)
                    : (option.active = true)
                }
                value={option.key}
                id={option.key}
                className="ios-input d-none"
              />{" "}
              <label
                className={`pointer custom-control-label pr-5 ${
                  classes.checkboxCenter
                }`}
                htmlFor={option.key}
              >
                {option.val}
              </label>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default LeagueFilter;
