import React from 'react';
import { useTranslation } from "react-i18next";
import CurrencyFormat from 'react-currency-format';

const SuccessSingle = ({ betSuccess, setBetSuccessModal }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="card">
        <div className="card-header bg-success border-none text-white font-weight-bold">
        {t("Bet Placed successfully")}
        </div>
        <div className="card-body">
          <p className={`text-body font-weight-bold mb-0`}>{betSuccess.comp}</p>
          <p className={`text-body mb-0`}>
            {betSuccess.home} vs {betSuccess.away}
          </p>
          {betSuccess.market === 'LIVE' ? (
            <p className={`text-danger mb-0`}>
              ({t("Running")} {betSuccess.homeGoal}-{betSuccess.awayGoal})
            </p>
          ) : null}

          <p className={`text-body font-weight-bold mb-0`}>
            {betSuccess.desc}{' '}
            {betSuccess.handicap !== '' ? (
              <span className="text-primary">({betSuccess.handicap})</span>
            ) : null}
          </p>
          {betSuccess.status === 'PENDING' ? (
            <p className={`text-danger mb-0`}>({betSuccess.status})</p>
          ) : null}
          <p className={`text-body font-weight-bold mb-0`}>
            @{' '}
            <CurrencyFormat
              value={betSuccess.odds}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </p>
          <div
            className={`d-flex justify-content-between align-items-center my-2`}
          >
            <p className={`text-body mb-0`}>{t("Amount")}</p>
            <p className={`text-body mb-0 font-weight-bold`}>
              {betSuccess.amount}
            </p>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center my-2`}
          >
            <p className={`text-body mb-0`}>{t("Payout")}</p>
            <p className={`text-body mb-0 font-weight-bold`}>
              {betSuccess.payout}
            </p>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center my-2`}
          >
            <p className={`text-body mb-0`}>{t("Account")}</p>
            <p className={`text-body mb-0 font-weight-bold`}>
              {betSuccess.account}
            </p>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center my-2`}
          >
            <p className={`text-body mb-0`}>{t("Bet no.")}</p>
            <p className={`text-body mb-0 font-weight-bold`}>
              {betSuccess.reference}
            </p>
          </div>
          {/* buttons */}
          <div className={`d-flex justify-content-center align-items-center`}>
            <button
              className="btn  btn-success btn-sm "
              onClick={() => setBetSuccessModal(false)}
            >
              {t("Done")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessSingle;
