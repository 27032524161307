import React from 'react';
import { useTranslation } from "react-i18next";
import '../../styles/MobileMain.css';
import CurrencyFormat from 'react-currency-format';
import Scrollbars from 'react-custom-scrollbars';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    background: '#86898C',
    borderRadius: '4px 4px 0 0',
  },
  borderDiv: {
    border: '0.5px solid #86898C',
  },
  cardBody: {
    boxShadow: '0px 2px 8px rgba(81, 81, 81, 0.4)',
    borderRadius: '0 0 4px 4px',
  },
  viewBtn: {
    border: '1px solid #86898C',
    color: '#86898C',
    backgroundColor: 'transparent',
  },
  reject: {
    textDecoration: 'line-through',
  },
  strong: {
    color: '#DC252F',
  },
  teams: {
    fontSize: '13px',
    color: '#18334C',
    margin: '0',
  },
  desc: {
    fontSize: '13px',
    fontWeight: 'bolder',
    color: '#323030',
    margin: '0',
  },
  compName: {
    color: '#0E2336',
    fontSize: '11px',
    fontWeight: 'bold',
    margin: '0.05rem 0',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
    margin: ' 0',
  },
  handiCap: {
    color: '#1452CC ',
    fontSize: '13px',
    fontWeight: 'bold',
    margin: ' 0',
    backgroundColor: 'transparent',
    border: 'none',
  },
  money: {
    color: '#1452CC',
    fontSize: '13px',
    fontWeight: 'bold',
    // margin: '0 ',
  },
  amount: {
    fontWeight: 'bold',
    fontSize: '13px',
    color: '#1452CC',
    margin: '0',
  },

  refresh: {
    cursor: 'pointer',
    height: '12px',
    width: '12px',
  },
  spacing: {
    padding: '0.15rem 0.75rem',
    borderBottom: '1px solid rgba(134, 137, 140, 0.7)',
    '& p': {
      margin: '0 ',
    },
  },
  waiting: {
    backgroundColor: '#f9eded',
  },

  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex2: {
    display: 'flex',
    padding: '0.15rem 0.75rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  printWrapper: {
    display: 'flex',
    '& a': {
      fontSize: '13px',
      margin: '0 0.25rem',
    },
  },
  Modal: {
    margin: '0 auto',
    width: '85vw',
    maxWidth: '480px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  division: {
    borderBottom: '1px solid #86898C',
  },
  card: {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px 5px rgba(0, 0, 0, 0.03)',
    borderRadius: '4px',
  },
  cardUpper: {
    backgroundColor: '#86898C',
    padding: '0.0rem 0.75rem',
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardLower: {
    padding: '0.15rem 0.75rem',
    borderRadius: '0 0 4px 4px ',
    '& p': {
      margin: '0 !important',
    },
  },
  heading: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  negative: {
    color: '#E91F1F',
  },
}));

const LegacyParlayInfo = ({ moreInfo, betId, mixType }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.Modal}>
        <div className={classes.card}>
          <div className={`${classes.cardUpper} p-3 `}>
            <p className={`${classes.heading} mb-0`}>{t("Mix Details")}</p>
          </div>
          <div className={classes.cardLower}>
            <div className={classes.division} style={{ margin: '0.35rem 0' }}>
              <p className={classes.teams}>
                {/* Account: <strong>{User.user.username}</strong>
                  <br /> */}
                {t("Bet No")}: <strong>{betId}</strong>
                <br />
                {t("Parlay")}:{' '}
                <strong>
                  {mixType} @{' '}
                  <CurrencyFormat
                    value={moreInfo.odds}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </strong>
              </p>
            </div>

            <Scrollbars
              autoHeight
              autoHeightMin={42}
              autoHeightMax={'42vh'}
              className={`border-bottom`}
            >
              {moreInfo.map((match, index) => (
                <React.Fragment key={index}>
                  {match.map((info, i) => (
                    <React.Fragment key={i}>
                      <div className={classes.division}>
                        <p
                          className={`${classes.compName} ${
                            info.flagCancel === true ? classes.reject : null
                          }`}
                        >
                          {moment(info.createdAt).format('DD/MM')} {info.comp}{' '}
                          {info.leagueName}
                        </p>
                        <p
                          className={`${classes.teams} ${
                            info.flagCancel === true ? classes.reject : null
                          }`}
                        >
                          <span
                            className={info.high === 1 ? classes.strong : null}
                          >
                            {info.homeName}
                          </span>{' '}
                          {t("vs")}{' '}
                          <span
                            className={info.high === 2 ? classes.strong : null}
                          >
                            {info.awayName}
                          </span>
                        </p>
                        {info.market === 'LIVE' ? (
                          <p className={classes.running}>
                            ({t("Running")} {info.score})
                          </p>
                        ) : null}
                        <p
                          className={`${classes.desc} ${
                            info.flagCancel === true ? classes.reject : null
                          }`}
                        >
                          {info.desc}
                          {info.hdp !== '' ? (
                            <span className={classes.handiCap}>
                              {' '}
                              ({info.hdp})
                            </span>
                          ) : null}
                        </p>

                        <div className={classes.flex}>
                          <div className={classes.printWrapper}>
                            <p className={`${classes.desc}`}>
                              @{' '}
                              <CurrencyFormat
                                value={info.odds}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </div>
                        </div>
                        {info.status === 'REJECTED' ? (
                          <p className={classes.running}>
                            {t("Reject")}{' '}
                            {info.rejectReason
                              ? '(' + info.rejectReason + ')'
                              : ''}
                          </p>
                        ) : info.betResult !== undefined ? (
                          <p
                            className={`${
                              info.betResult === 'LOSE' ||
                              info.betResult === 'LOSE_HALF'
                                ? 'text-danger'
                                : null
                            }`}
                          >
                            {' '}
                            {info.betResult !== ''
                              ? info.betResult.charAt(0).toUpperCase() +
                                info.betResult
                                  .slice(1)
                                  .toLowerCase()
                                  .replace('_', ' ')
                              : '-'}
                          </p>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </Scrollbars>
            <div className={classes.flex} style={{ margin: '0.35rem 0' }}>
              <div className={classes.printWrapper}>
                <p className={classes.desc}>{t("Total")}</p>
              </div>
              <p className={classes.amount}>
                <CurrencyFormat
                  value={moreInfo.amount}
                  decimalScale={2}
                  displayType={'text'}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
            <div
              className={`${classes.flex} justify-content-end`}
              style={{ margin: '0.35rem 0' }}
            >
              <p className={classes.desc}>
              {t("Date")}:
                {moment(moreInfo.infoTime).format(' DD MMM YYYY, h:mm a')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegacyParlayInfo;
