import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';

import Login from './pages/Login';
import Error from './pages/Error';

import Odds from './pages/Odds';
import SingleBet from './pages/SingleBet';

import Withdraw from './pages/main/Withdraw';
import Me from './pages/main/Me';
import ChangePassword from './pages/main/ChangePassword';
import Result from './pages/main/Result';
import News from './pages/main/News';
import Statement from './pages/main/Statement';
import ViewCash from './pages/main/Cash';
import ViewCashDetail from './pages/main/CashDetail';
import StatementHistory from './pages/main/StatementHistory';
import Parley from './pages/Parley';
import { HistoryProvider } from './context/HistoryContext';
import { ParlayProvider } from './context/ParlayContext';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import ChangeOddsType from './pages/main/ChangeOddsType';
import ChangeLang from './pages/main/ChangeLang';
import Maintenance from './pages/Maintenance';
import PwdExpiry from './pages/PwdExpiry';
import PageVisibility from 'react-page-visibility';
import LegacyDetails from './pages/LegacyDetails';
import {ErrorBoundary} from 'react-error-boundary'
import Analytics from 'react-router-ga';
import { isIOS } from 'react-device-detect';
// import { getToken, onMessageListener } from './firebase';
const REACT_APP_GA_CODE = process.env.REACT_APP_GA_CODE;

function App() {
  const { t, i18n } = useTranslation();
  const [pushTitle, setPushTitle] = useState('');
  const [pushDetail, setPushDetail] = useState('');

/*
  const getParameterByName = (name, url = window.location.href) => {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  const lang = getParameterByName('lang');
  if(lang) {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
  }
  */

  /*
  useEffect(() => {
    getToken();

    onMessageListener().then(payload => {
      window.$('#pushnotify').toast('show');
      console.log(payload);
      setPushTitle(payload.notification.title);
      setPushDetail(payload.notification.body);
    }).catch(err => console.log('failed: ', err));
  }); */

  /*
  //Onesignal
  useEffect(() => {
    var script = document.createElement("script");
    script.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
    var callback = function() {
      window.OneSignal = window.OneSignal || [];
      window.OneSignal.push(function() {
        window.OneSignal.init({
          appId: process.env.REACT_APP_ONE_SIGNAL_ID,
          notifyButton: {
            enable: true,
          },
        });
      });
    };
    script.onload = callback;
    document.head.appendChild(script);
  });

  */

  const getPWADisplayMode = () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      return 'twa';
    } else if (navigator.standalone || isStandalone) {
      return 'standalone';
    }
    return 'browser';
  }

  //Progressier
  useEffect(() => {
    if (isIOS) {
      if (getPWADisplayMode() === 'browser') {
        var script = document.createElement("script");
        script.src = `https://progressier.com/client/script.js?id=${process.env.REACT_APP_PROGRESSIER_ID}`;
        var callback = function() {
          window.progressier.promote();
        };
        script.onload = callback;
        document.head.appendChild(script);
      }
    }
  });

  const handleVisibilityChange = (isVisible) => {
    if (!isVisible) {
      localStorage.setItem('wentOffline', 'yes');
    } else {
      var wentOffline = localStorage.getItem('wentOffline');
      if (wentOffline && wentOffline === 'yes') {
        localStorage.setItem('wentOffline', 'no');
        window.location.reload();
      }
    }
  };

  return (
    <>
      <ErrorBoundary
        FallbackComponent={Error}
        onReset={() => {
        }}
      >
        
      <div id="pushnotify" data-dismiss="notification-4" data-delay={30000} data-autohide="true" className="notification bg-dark-dark shadow-xl">
        <div className="toast-header  bg-dark-dark">
          <img src="images/preload-logo.png" width={15} className="rounded mr-2" alt="..." />
          <strong className="mr-auto color-white">{pushTitle}</strong>
          <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span className="color-white" aria-hidden="true">×</span>
          </button>
        </div>
        <div className="toast-body color-white">
          {pushDetail}
        </div>
      </div>

      <PageVisibility onChange={handleVisibilityChange}>
        <DeviceOrientation lockOrientation={'portrait'}>
          {/* Will only be in DOM in landscape */}
          <Orientation orientation="portrait" alwaysRender={false}>
            <ParlayProvider>
              <HistoryProvider>
                <Router>
                <Analytics id={REACT_APP_GA_CODE}>
                  <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/error" component={Error} />
                    <Route exact path="/maintenance" component={Maintenance} />
                    <ProtectedRoute
                      exact
                      path="/mobile/me/withdraw"
                      component={Withdraw}
                    />
                    <ProtectedRoute
                      exact
                      path="/single"
                      component={SingleBet}
                    />

                    <ProtectedRoute exact path="/parlay" component={Parley} />

                    <ProtectedRoute exact path="/mobile/me" component={Me} />
                    <ProtectedRoute
                      exact
                      path="/mobile/me/change-password"
                      component={ChangePassword}
                    />
                    <ProtectedRoute
                      exact
                      path="/pwdexpiry"
                      component={PwdExpiry}
                    />
                    <ProtectedRoute
                      exact
                      path="/mobile/me/change-odds-type"
                      component={ChangeOddsType}
                    />
                    <Route
                      exact
                      path="/mobile/me/change-language"
                      component={ChangeLang}
                    />
                    <ProtectedRoute
                      exact
                      path="/mobile/me/cash"
                      component={ViewCash}
                    />
                    <ProtectedRoute
                      exact
                      path="/mobile/me/cash/:date"
                      component={ViewCashDetail}
                    />
                    <ProtectedRoute
                      exact
                      path="/mobile/result"
                      component={Result}
                    />
                    <ProtectedRoute
                      exact
                      path="/mobile/news"
                      component={News}
                    />
                    <ProtectedRoute
                      exact
                      path="/mobile/statement"
                      component={Statement}
                    />
                    <ProtectedRoute
                      exact
                      path="/mobile/me/history/:date"
                      component={StatementHistory}
                    />
                    <ProtectedRoute
                      exact
                      path="/mobile/me/legacy/history/:date"
                      component={LegacyDetails}
                    />
                    <ProtectedRoute exact path="/odds" component={Odds} />
                    <Route render={() => <Redirect to="/odds" />} />
                  </Switch>
                </Analytics>
                </Router>
              </HistoryProvider>
            </ParlayProvider>
          </Orientation>
          {/* Will stay in DOM, but is only visible in portrait */}
          <Orientation orientation="landscape" alwaysRender={false}>
            <div className="text-center">
              <img
                src={require('./img/orientation-check.png')}
                alt=""
                className=" my-3"
              />
              <p className="text-body font-weight-bold">
              {t("Please rotate your device")}{' '}
              </p>
            </div>
          </Orientation>
        </DeviceOrientation>
      </PageVisibility>
      </ErrorBoundary>
    </>
  );
}

export default App;
