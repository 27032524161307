import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
//import Header from '../components/Header';
import '../styles/Mobile.css';
//import { ParlayContext } from '../context/ParlayContext';
import qs from 'qs';
import axiosInstance from '../config';
import CurrencyFormat from 'react-currency-format';
//import Scrollbars from 'react-custom-scrollbars';
import Error from '../components/Error';

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: '#DEDEDE',
  },
  teamWrapper: {
    border: '0.5px solid #86898C',
  },
  oddsWrapperColored: {
    background: '#F5F5F5',
    // border: '0.5px solid #888E99',
  },
  oddsWrapper: {
    background: '#FFF',
    // border: '0.5px solid #888E99',
  },
  secondaryColor: {
    color: '#18334C',
  },
  tertiaryColor: {
    color: '#1452CC',
  },
  highlightColor: {
    color: '#E91F1F',
  },
  compName: {
    color: '#0E2336',
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '0',
    // marginTop: '0.25rem',
  },
  vs: {
    color: '#86898C',
    fontSize: '11px',
    margin: '0',
    // marginTop: '0.25rem',
  },
  selectedColor: {
    fontWeight: 'bold',
    color: '#0E2336',
  },
  oddColor: {
    color: '#1452CC',
  },
  betBtn: {
    background: '#32A352',
    borderRadius: '6px',
    fontSize: '13px',
    width: '125px',
    height: '28px',
    fontWeight: 'bold',
    lineHeight: '1',
  },
  cancelBtn: {
    border: '1px solid #ED9297',
    borderRadius: '6px',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '13px',
    height: '28px',
    backgroundColor: '#ED9297',
    lineHeight: '1',
    // cursor: 'pointer',
  },

  betWrapper: {
    background: '#F0F5FF',
    border: '0.5px solid #86898C',
    padding: '0.9375rem ',
    position: 'relative',
  },

  amtInput: {
    width: '135px',
    height: '24px',
    border: '1px solid #323030',
    borderRadius: '4px',
    backgroundColor: '#fff !important',
  },
  remove: {
    position: 'absolute',
    bottom: '17px',
    right: '10px',
  },
  radio: {
    color: '#86898C',
    width: '14px',
    height: '14px',
  },
  // font sizes
  fntSm: {
    fontSize: '12px',
  },
  fntMd: {
    fontSize: '14px',
  },
  fntLg: {
    // fontSize: '3.5vw',
    fontSize: '16px',
  },
  betLabel: {
    color: '#86898C',
    fontSize: '13px',
  },
  cardHeader: {
    backgroundColor: '#86898C !important',
  },
}));
const Parley = ({
  setParlayModal,
  parlayBet,
  setParlayBet,
  parlaySuccess,
  setParlaySuccess,
  setBetSuccessModal,
  oddError,
  setOddError,
  oddUpdating,
  setOddUpdating,
  addParlay,
  setAddParlay,
  handleRemove,
  clearOddSelection,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [payoutValue, setPayoutValue] = useState('');
  const [money, setMoney] = useState(0);
  const [amountM, setAmountM] = useState('');
  const [isBetWarning, setIsBetWarning] = useState('hide');
  const [type, setType] = useState('');
  const [isBetInProgress, setBetInProgress] = useState(false);
  let displayQuote = 'block';

  // handle payout value
  const handlePayoutValue = (e) => {
    setPayoutValue(e.target.value);
    let index = e.target.selectedIndex;
    let el = e.target.childNodes[index];
    let option = el.getAttribute('id');
    let amount = el.getAttribute('amount');
    setType(option);
    setAmountM(amount);
  };

  // handle money
  const handleMoney = (e) => {
    setMoney(e.target.value);
  };

  // handle bet
  const handleBet = (e) => {
    e.preventDefault();
    if(!isBetInProgress) {
      const betForm = document.querySelector('#parlayForm');
      const betMoney = betForm['betMoney'].value;
      const requestBody = {
        quote: parlayBet.quote,
        type: type === '' ? parlayBet.types[0].key : type,
        amount: betMoney,
      };
      displayQuote = 'none';
      setBetInProgress(true);
      axiosInstance
        .post('restricted/bet', qs.stringify(requestBody))
        .then((res) => {
          if (res.data.bet !== undefined) {
            clearOddSelection();
            // console.log(res.data);
            setParlaySuccess(res.data);
            setParlayModal(false);
            setBetSuccessModal(true);
            setParlayBet(undefined);
            setOddUpdating(false);
          } else {
            displayQuote = 'block';
            setOddUpdating(true);
            //console.log('setting quote data because odd is updating');
            setParlayBet(res.data);
          }
        })
        .catch((err) => {
          if(err.response && err.response.data && 
            (err.response.data.message === 'AAS001' || err.response.data.message === 'AAS002')) {
            setIsBetWarning('show');
          }
          console.log(err);
        })
        .finally(() => {
          setBetInProgress(false);
        });
    }
  };

  useEffect(() => {
    // do not set new timer when adding new parlay
    if (!addParlay && parlayBet !== undefined) {
      // do not set new timer when odd is updated
      if (document.querySelector('#updateOdd') != null) {
        const updateOdd = document.querySelector('#updateOdd').value;
        // console.log('updateOdd = ' + updateOdd);
        if (updateOdd === 'Y') {
          // prevent double call
          return;
        }
      }
      // first call from tableinfo - trigger refreshOdd
      refreshOdd();
    }
  }, [parlayBet, history, addParlay]);

  const refreshOdd = () => {
    if (document.querySelector('#quoteId') != null) {
      // always true
      let qid = document.querySelector('#quoteId').value;
      if (qid === '' && addParlay === true) {
        return;
      }
      if (qid !== '' && qid !== parlayBet.quote) {
        // previous run, do not spawn another timer
        //console.log('previod run, do not spawn another timer');
        return;
      }
      axiosInstance
        .get('restricted/parlay/' + parlayBet.quote + '/update')
        .then((res) => {
          if (res.data.odds !== undefined) {
            setParlayBet(res.data);
            document.querySelector('#updateOdd').value = 'Y';
            setOddUpdating(true);
            //console.log('setting quote data');
          } else {
            document.querySelector('#updateOdd').value = '';
            setOddUpdating(false);
          }
          // document.querySelector('#quoteId').value = qid;
          setTimeout(() => {
            refreshOdd();
          }, 10000);
          // console.log('refreshTimer start = '+refreshTimer);
        })
        .catch((err) => {
          setOddError(true);
          if (err.response && err.response.data.status === 401) {
            localStorage.removeItem('token');
            history.push('/');
          }
        });
    }
  };

  return (
    <>
      <Error isBetWarning={isBetWarning} message={t("Submit failed. Wait for your upline to finish settlement.")} onAction={() => {
      setIsBetWarning('hide');
    }} />

      <div className="card" display={displayQuote}>
        <div
          className={`card-header ${classes.cardHeader} border-none text-white px-3 py-2 `}
        >
          {t("Your Bets (Mix Parlay)")}
        </div>
        {oddError === false ? (
          <>
            <div className="card-body p-0 mb-2">
              {parlayBet.parlays.map((parlayMatch, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? `${classes.oddsWrapper} px-3 py-1 position-relative`
                      : `${classes.oddsWrapperColored} px-3 py-1 position-relative`
                  }
                  key={index}
                >
                  <p className={`text-body font-weight-bold mb-0`}>
                    {parlayMatch.comp}
                  </p>
                  <p className={`text-body mb-0`}>
                    {' '}
                    <span
                      className={parlayMatch.high === 1 ? 'text-danger' : null}
                    >
                      {parlayMatch.home}
                    </span>{' '}
                    {t("vs")}{' '}
                    <span
                      className={parlayMatch.high === 2 ? 'text-danger' : null}
                    >
                      {parlayMatch.away}
                    </span>{' '}
                  </p>
                  {parlayMatch.market === 'LIVE' ? (
                    <p className={`text-danger mb-0 font-weight-bold`}>
                      ({t("Running")} {parlayMatch.homeGoal}-{parlayMatch.awayGoal})
                    </p>
                  ) : null}

                  <p className={`text-body font-weight-bold mb-0`}>
                    {parlayMatch.desc}{' '}
                    {parlayMatch.handicap !== '' ? (
                      <span className={`text-primary`}>
                        ({parlayMatch.handicap})
                      </span>
                    ) : null}{' '}
                    @{' '}
                    <CurrencyFormat
                      value={parlayMatch.odds}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </p>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classes.remove}
                    onClick={() => {
                      handleRemove(parlayMatch.quote, parlayMatch.odd);
                    }}
                  >
                    <path
                      d="M8 1.33333C11.676 1.33333 14.6667 4.324 14.6667 8C14.6667 11.676 11.676 14.6667 8 14.6667C4.324 14.6667 1.33333 11.676 1.33333 8C1.33333 4.324 4.324 1.33333 8 1.33333ZM8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM12 11.0253L8.93867 7.99333L11.9693 4.93533L11.0253 4L7.99533 7.05933L4.93667 4.03067L4 4.96733L7.062 8.002L4.03067 11.0633L4.96733 12L8.004 8.936L11.0647 11.9693L12 11.0253Z"
                      fill="#888E99"
                    />
                  </svg>
                </div>
              ))}
            </div>
            <div className="card-body p-0">
              {oddUpdating === true ? (
                <div className={classes.cardLower}>
                  <p className={classes.warning}>{t("Odd is updated")}</p>
                </div>
              ) : null}
              {/* amount area */}
              {parlayBet.parlays.length !== 0 &&
              parlayBet !== undefined &&
              parlayBet.types[0] !== undefined ? (
                <form id="parlayForm" action="" onSubmit={handleBet}>
                  <input type="hidden" id="quoteId" value={parlayBet.quote} />
                  <input type="hidden" id="updateOdd" value="" />
                  <div
                    className={`d-flex justify-content-between align-items-center w-100 px-3 my-1`}
                  >
                    <p className={`font-weight-bold mb-0`}>{t("Types")}</p>
                    <select
                      name=""
                      id="type"
                      className={`${classes.amtInput} `}
                      onChange={handlePayoutValue}
                    >
                      {parlayBet.types.map((typ, index) => (
                        <option
                          value={typ.oddM}
                          key={index}
                          id={typ.key}
                          amount={typ.amountM}
                        >
                          {typ.val}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className={`d-flex justify-content-between align-items-center w-100 px-3 my-1`}
                  >
                    <p className={`mb-0`}>
                      <strong>{t("Amount")}</strong>
                      <br />
                      ({t("Min")}: <strong>{parlayBet.minBet}</strong>, {t("Max")}:{' '}
                      <strong>{parlayBet.maxBet}</strong>)
                    </p>
                    <input
                      type="number"
                      className={`${classes.amtInput} `}
                      onChange={handleMoney}
                      required
                      id="betMoney"
                      min={parlayBet.minBet}
                      max={parlayBet.maxBet}
                    />
                  </div>
                  {/* <p className={`text-secondary mb-0 px-3`}>
                Min: <strong>{parlayBet.minBet}</strong>, Max:{' '}
                <strong>{parlayBet.maxBet}</strong>
              </p> */}
                  <div
                    className={`d-flex justify-content-between align-items-center w-100 px-3 my-1`}
                  >
                    <p className={`font-weight-bold mb-0`}>{t("Payout")}</p>
                    {payoutValue !== '' ? (
                      <p className={`text-primary mb-0 font-weight-bold`}>
                        <CurrencyFormat
                          value={payoutValue * money <= 50000 ? payoutValue * money : 50000}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </p>
                    ) : (
                      <p className={`text-primary mb-0 font-weight-bold`}>
                        <CurrencyFormat
                          value={parlayBet.types[0].oddM * money <= 50000 ? parlayBet.types[0].oddM * money : 50000}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </p>
                    )}
                  </div>
                  <div
                    className={`d-flex justify-content-between align-items-center w-100 px-3 my-1`}
                  >
                    <p className={`font-weight-bold mb-0`}>{t("Price")}</p>
                    {amountM !== '' ? (
                      <p className={`text-primary mb-0 font-weight-bold`}>
                        <CurrencyFormat
                          value={amountM * money}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </p>
                    ) : (
                      <p className={`text-primary mb-0 font-weight-bold`}>
                        <CurrencyFormat
                          value={parlayBet.types[0].amountM * money}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </p>
                    )}
                  </div>
                  {/* buttons */}
                  <div className="d-flex justify-content-around my-2">
                    <button
                      type="submit"
                      className={`${classes.betBtn} ${classes.fntMd} text-white btn px-3 py-1 ml-3`}
                    >
                      {t("Bet Now")}
                    </button>
                    <button
                      className={`${classes.cancelBtn} ${classes.fntMd} px-3 py-1 btn mr-3`}
                      onClick={() => {
                        setParlayModal(false);
                      }}
                    >
                      {t("Close")}
                    </button>
                  </div>
                </form>
              ) : (
                <div
                  className={`d-flex justify-content-center my-3`}
                  style={{ height: '50px' }}
                >
                  <button
                    className={`${classes.cancelBtn} btn-sm px-3 py-1 btn`}
                    onClick={() => {
                      setParlayModal(false);
                    }}
                  >
                    {t("Add more")}
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          /// odd Error
          <div className={classes.cardLower}>
            <div className={classes.flex2}>
              <p className={classes.warning}>{t("Odd is updating")} ...</p>
            </div>
            <div>
              <center>
                <button
                  className={classes.betBtn}
                  onClick={() => setParlayModal(false)}
                >
                  {t("Close")}
                </button>
              </center>
              <p></p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Parley;
