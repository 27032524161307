import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../config";
import "../../styles/MobileMain.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: "#fff",
    boxShadow: "0px 10px 20px 5px rgba(0, 0, 0, 0.03)",
    borderRadius: "4px"
  },
  spacing: {
    padding: "0.15rem 0.75rem",
    borderBottom: "1px solid rgba(134, 137, 140, 0.7)",
    "& p": {
      margin: "0 "
    }
  }
}));

const CashDetail = props => {
  const history = useHistory();
  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(
    () => {
      // console.log(props);
      setLoading(true);
      axiosInstance
        .get(`portal/transactions?date=${props.match.params.date}`)
        .then(res => {
          // console.log(res);
          // console.log(res.data.totalPages);
          // setTotalPage(res.data.totalPages);
          setDetail(res.data.content);
        })
        .catch(err => {
          console.log(err);
          if (err.response && err.response.data.status === 412) {
            history.push("/cash");
          } else if (err.response && err.response.data.status === 401) {
            localStorage.removeItem('token');
            history.push("/");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [props, history]
  );

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">
        {t("Cash Detail")}
        </span>

        <span
          className="header-icon header-icon-1"
          onClick={() => {
            history.goBack();
          }}
        >
          <i className="fas color-white fa-arrow-left color-red-light" />
        </span>
      </div>
      <div className="page-title-clear" />
      <div className="page-content">
        {loading ? (
          <LoadingPage />
        ) : (
          <>
            {detail.length > 0 ? (
              <>
                {detail.map((info, index) => (
                  <>
                    <div className={`${classes.card}`}>
                      <div className={`${classes.spacing}`}>
                        <strong>
                          {moment(info.createdAt).format("D MMM h:mm:ss a")}
                        </strong>
                        <table className="list-table-detail mt-1">
                          <tr>
                            <td className="font-weight-bold list-table-detail-title-30">
                            {t("Transaction")}
                            </td>
                            <td style={{ color: "#888e99" }}>
                              {info.transactionType === "BET"
                                ? `Bet ${info.remarks}`
                                : info.transactionType === "BET_RESULT"
                                  ? `Bet Result ${info.remarks}`
                                  : info.transactionType
                                      .charAt(0)
                                      .toUpperCase() +
                                    info.transactionType.slice(1).toLowerCase()}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold list-table-detail-title-30">
                            {t("Amount")}
                            </td>
                            <td>
                              {info.debit === 0 ? (
                                <font color="#1452CC">
                                  <CurrencyFormat
                                    value={info.credit}
                                    decimalScale={2}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                </font>
                              ) : (
                                <font color="#E91F1F">
                                  <CurrencyFormat
                                    value={info.debit}
                                    decimalScale={2}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                </font>
                              )}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <EmptyPage icon={"fa-cash-stack"} title={t("No Cash Detail")} />
            )}
          </>
        )}
      </div>
      {/* Page content ends here*/}
    </>
  );
};

export default CashDetail;
