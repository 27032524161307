import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//import PropTypes from "prop-types";
import axiosInstance from "../../config";
import "../../styles/MobileMain.css";
import { useHistory } from "react-router-dom";

const ChangeLang = (props) => {
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState("");
  const [loading, setLoading] = useState(false);

  const updateLang = () => {
    setLoading(true);
    localStorage.setItem("language", currentLang);
    i18n.changeLanguage(currentLang);
    setLoading(false);
    window.$("#toastSuccess").toast("show");

    history.goBack();
  };

  useEffect(() => {
    setCurrentLang(localStorage.getItem("language") || "en");
  }, []);

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">{t("Change Language")}</span>

        <span
          className="header-icon header-icon-1"
          onClick={() => {
            history.goBack();
          }}
        >
          <i className="fas color-white fa-arrow-left color-red-light"></i>
        </span>
      </div>
      <div className="page-title-clear" />
      <div className="page-content">
        <div className="card card-style mt-3 rounded">
          <div className="content">
            <div
              className="d-flex pb-3 pt-3"
              onClick={() => {
                setCurrentLang("en");
              }}
            >
              <div className="color-theme">English</div>
              <div className="ml-auto ml-3">
                {currentLang === "en" ? <i className="fa fa-check"></i> : null}
              </div>
            </div>

            <div className="divider" style={{ marginBottom: 0 }}></div>

            {/* <div
              className="d-flex pb-3 pt-3"
              onClick={() => {
                setCurrentLang("cn");
              }}
            >
              <div className="color-theme">中国</div>
              <div className="ml-auto ml-3">
                {currentLang === "cn" ? <i className="fa fa-check"></i> : null}
              </div>
            </div>

            <div className="divider" style={{ marginBottom: 0 }}></div> */}
            <div
              className="d-flex pb-3 pt-3"
              onClick={() => {
                setCurrentLang("kh");
              }}
            >
              <div className="color-theme">កម្ពុជា</div>
              <div className="ml-auto ml-3">
                {currentLang === "kh" ? <i className="fa fa-check"></i> : null}
              </div>
            </div>

            <div className="divider" style={{ marginBottom: 0 }}></div>
            <div
              className="d-flex pb-3 pt-3"
              onClick={() => {
                setCurrentLang("th");
              }}
            >
              <div className="color-theme">ประเทศไทย</div>
              <div className="ml-auto ml-3">
                {currentLang === "th" ? <i className="fa fa-check"></i> : null}
              </div>
            </div>

            <div className="divider" style={{ marginBottom: 0 }}></div>
            <div
              className="d-flex pb-3 pt-3"
              onClick={() => {
                setCurrentLang("vn");
              }}
            >
              <div className="color-theme">Việt Nam</div>
              <div className="ml-auto ml-3">
                {currentLang === "vn" ? <i className="fa fa-check"></i> : null}
              </div>
            </div>

            <div className="divider" style={{ marginBottom: 0 }}></div>
          </div>
        </div>
        {loading ? (
          <div className="text-center" style={{ marginTop: 16 }}>
            <div className="spinner-border color-red-dark" role="status">
              <span className="sr-only">{t("Loading")}...</span>
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              updateLang();
            }}
            className="btn btn-s btn-full mb-3 ml-3 mr-3 rounded-xs font-900 btn-green"
          >
            {t("Update")}
          </div>
        )}
      </div>
      {/* Page content ends here*/}

      <div
        id="toastSuccess"
        className="toast toast-tiny toast-bottom bg-green-dark fade hide toast-custom"
        data-delay="3000"
        data-autohide="true"
      >
        <i className="fa fa-times mr-3"></i>
        {t("Language updated")}
      </div>
    </>
  );
};

export default ChangeLang;
