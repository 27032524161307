import qs from 'qs';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import axiosInstance from '../config';
import Error from '../components/Error';

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: '#DEDEDE',
  },
  oddsWrapper: {
    background: '#F0F5FF',
    border: '0.5px solid #888E99',
  },
  betBtn: {
    background: '#32A352',
    borderRadius: '6px',
    fontSize: '13px',
    width: '125px',
    height: '28px',
    fontWeight: 'bold',
    lineHeight: '1',
  },
  cancelBtn: {
    border: '1px solid #ED9297',
    borderRadius: '6px',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '13px',
    width: '90px',
    height: '28px',
    backgroundColor: '#ED9297',
    lineHeight: '1',
  },
  amtInput: {
    width: '135px',
    height: '24px',
    border: '1px solid #323030',
    borderRadius: '4px',
    textAlign: 'right',
  },
  radio: {
    color: '#86898C',
    width: '14px',
    height: '14px',
  },
  fntMd: {
    fontSize: '14px',
  },
  betLabel: {
    color: '#86898C',
    fontSize: '13px',
  },
  cardHeader: {
    backgroundColor: '#86898C !important',
    fontWeight: 'bold',
  },
  cardLower: {
    padding: '0.15rem 0.75rem',
    borderRadius: '0 0 4px 4px ',
  },
  warning: {
    width: '100%',
    textAlign: 'center',
    border: '1px solid red',
    color: '#E91F1F',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  betBtn2: {
    backgroundColor: '#4EAD26',
    borderRadius: '6px',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '13px',
    border: 'none',
    width: '125px',
    height: '30px',
    cursor: 'pointer',
    }
}));

const SingleBet = ({
  setOpen,
  singleBet,
  setSingleBet,
  setBetSuccessModal,
  setBetSuccess,
  setOddError,
  oddError,
  oddUpdating,
  setOddUpdating,
  clearOddSelection,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [finalPayout, setFinalPayout] = useState(0);
  const [betMoney, setBetMoney] = useState(0);
  //const [radio, setRadio] = useState('N');
  const [isBetWarning, setIsBetWarning] = useState('hide');
  const [isBetInProgress, setBetInProgress] = useState(false);
  let displayQuote = 'block';
  let quoteId;

  // handle radio
  // const handleRadio = (e) => {
  //   setRadio(e.target.value);
  // };

  const handlePayout = (e, payout) => {
    setBetMoney(e.target.value);
    setFinalPayout(e.target.value * payout);
  };

  // handle single bet
  const handleSingleBet = (e) => {
    e.preventDefault();
    if(!isBetInProgress) {
      const requestBody = {
        quote: singleBet.quote,
        amount: betMoney,
        accept: 'B',
      };
      displayQuote = 'none';
      // console.log('bet quote = ' + singleBet.quote);
      setBetInProgress(true);
      axiosInstance
        .post('restricted/bet', qs.stringify(requestBody))
        .then((res) => {
          if (res.data.bet !== undefined) {
            // console.log(res.data);
            clearOddSelection();
            setOpen(false);
            setBetSuccess(res.data);
            setBetSuccessModal(true);
          } else {
            displayQuote = 'block';
            setOddUpdating(true);
            //console.log('setting quote data because odd is updating');
            setSingleBet(res.data);
          }
        })
        .catch((err) => {
          if(err.response && err.response.data && 
            (err.response.data.message === 'AAS001' || err.response.data.message === 'AAS002')) {
            setIsBetWarning('show');
          }
          console.log(err);
        })
        .finally(() => {
          setBetInProgress(false);
        });
    }
  };

  useEffect(() => {
    if (!oddUpdating && singleBet !== null) {
      if (document.querySelector('#updateOdd') != null) {
        const updateOdd = document.querySelector('#updateOdd').value;
        if (updateOdd === 'Y') {
          // prevent double call
          return;
        }
      }
      // first call from tableinfo - trigger refreshOdd
      refreshOdd();
    }
  }, [history, oddUpdating, singleBet]);

  // refresh odd
  const refreshOdd = () => {
    if (document.querySelector('#quoteId') != null)
      // console.log(
      //   'refreshing - qid = ' + document.querySelector('#quoteId').value
      // );
    if (document.querySelector('#quoteId') != null) {
      const qid = document.querySelector('#quoteId').value;
      if (qid === '') return;
      if (quoteId !== undefined && quoteId !== qid) {
        // previous run, do not spawn another timer
        // console.log('quoteId = ' + quoteId + ', qid = ' + qid);
        return;
      }
      quoteId = qid;
      axiosInstance
        .get('restricted/quote/' + qid + '/update')
        .then((res) => {
          if (res.data.odds !== undefined) {
            setSingleBet(res.data);
            document.querySelector('#updateOdd').value = 'Y';
            setOddUpdating(true);
            //console.log('setting quote data');
          } else {
            document.querySelector('#updateOdd').value = '';
            setOddUpdating(false);
          }
          //console.log('setting timeout');
          setTimeout(() => {
            refreshOdd();
          }, 10000);
          // console.log('refreshTimer start = '+refreshTimer);
        })
        .catch((err) => {
          setOddError(true);
          if (err.response && err.response.data.status === 401) {
            localStorage.removeItem('token');
            history.push('/');
          }
        });
    }
  };

  return (
    <>
    <Error isBetWarning={isBetWarning} message={t("Submit failed. Wait for your upline to finish settlement.")} onAction={() => {
      setIsBetWarning('hide');
    }} />
      
      {singleBet !== undefined ? (
        <>
          <div
            className={`w-100   position-relative `}
            display={displayQuote}
          >
            <div className={`card`}>
              <div
                className={`card-header ${classes.cardHeader} border-none text-white`}
              >
                {t("Your Bet")}
              </div>
              {oddError === false ? (
                <form action="" onSubmit={handleSingleBet}>
                  <div className="card-body">
                    <p className={`text-body font-weight-bold mb-0`}>
                      {singleBet.comp}
                    </p>
                    <p className={`text-body mb-0`}>
                      {' '}
                      <span
                        className={singleBet.high === 1 ? 'text-danger' : null}
                      >
                        {singleBet.home}
                      </span>{' '}
                      {t("vs")}{' '}
                      <span
                        className={singleBet.high === 2 ? 'text-danger' : null}
                      >
                        {singleBet.away}
                      </span>{' '}
                    </p>
                    {singleBet.market === 'LIVE' ? (
                      <p className={`text-danger mb-0 font-weight-bold`}>
                        ({t("Running")} {singleBet.homeGoal}-{singleBet.awayGoal})
                      </p>
                    ) : null}
                    <p className={`text-body font-weight-bold mb-0 pt-2`}>
                      {singleBet.desc}{' '}
                      {singleBet.handicap !== '' ? (
                        <span className={`text-primary`}>
                          ({singleBet.handicap})
                        </span>
                      ) : null}{' '}
                      @{' '}
                      <CurrencyFormat
                        value={singleBet.odds}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </p>
                    {oddUpdating === true ? (
                      <p className={classes.warning}>{t("Odd is updated")}</p>
                    ) : null}
                    <div
                      className={`d-flex justify-content-between align-items-center w-100 my-2`}
                    >
                      <p className={`mb-0`}>
                        <strong>{t("Amount")}</strong>
                        <br />
                        ({t("Min")}: <strong>{singleBet.minBet}</strong>, {t("Max")}:{' '}
                        <strong>{singleBet.maxBet}</strong>)
                      </p>
                      <input
                        type="number"
                        className={`${classes.amtInput}`}
                        onChange={(e) => {
                          handlePayout(e, singleBet.payout);
                        }}
                        required
                        min={singleBet.minBet}
                        max={singleBet.maxBet}
                      />
                      <input
                        type="hidden"
                        id="quoteId"
                        value={singleBet.quote}
                      />
                      <input type="hidden" id="updateOdd" value="" />
                    </div>
                    <div
                      className={`d-flex justify-content-between align-items-center my-1`}
                    >
                      <p className={`font-weight-bold mb-0`}>
                      {t("Payout")}
                      </p>
                      <p className={`text-primary font-weight-bold mb-0`}>
                        <CurrencyFormat
                          value={finalPayout}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </p>
                    </div>
                    {/* odds area */}
                    {/* <div className="w-50 mx-auto align-items-center">
                      <div
                        className={`${classes.betLabel} d-flex align-items-center`}
                      >
                        <input
                          type="radio"
                          value="N"
                          id="N"
                          name="odds"
                          defaultChecked
                          onChange={handleRadio}
                          className={`${classes.radio}`}
                        />
                        <label htmlFor="N" className={`mb-0 mx-2`}>
                        {t("Accept this odd")}
                        </label>
                      </div>
                      <div
                        className={`${classes.betLabel} d-flex align-items-center`}
                      >
                        <input
                          type="radio"
                          value="A"
                          id="A"
                          name="odds"
                          onChange={handleRadio}
                          className={`${classes.radio}`}
                        />
                        <label htmlFor="A" className={`mb-0 mx-2`}>
                        {t("Accept any odd")}
                        </label>
                      </div>
                      <div
                        className={`${classes.betLabel} d-flex align-items-center`}
                      >
                        <input
                          type="radio"
                          value="B"
                          id="B"
                          name="odds"
                          onChange={handleRadio}
                          className={`${classes.radio}`}
                        />
                        <label htmlFor="B" className={`mb-0 mx-2`}>
                        {t("Accept better odd")}
                        </label>
                      </div>
                    </div> */}
                    {/* buttons */}
                    <div className="d-flex justify-content-around my-2">
                      <button
                        type="submit"
                        className={`${classes.betBtn} ${classes.fntMd} text-white btn px-3 py-1 ml-3`}
                      >
                        {t("Bet Now")}
                      </button>
                      <button
                        className={`${classes.cancelBtn} ${classes.fntMd} px-3 py-1 btn mr-3`}
                        onClick={() => {
                          clearOddSelection();
                          setOpen(false);
                        }}
                      >
                        {t("Cancel")}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                /// odd Error
                <div className={classes.cardLower}>
                  <div
                    className={`d-flex justify-content-between my-2 align-items-center`}
                  >
                    <p className={classes.warning}>{t("Odd is updating")} ...</p>
                  </div>
                  <div>
                    <center>
                      <button
                        className={classes.betBtn2}
                        onClick={() => {
                          clearOddSelection();
                          setOpen(false);
                          // setSingleBet(undefined)
                        }}
                      >
                        {t("Close")}
                      </button>
                    </center>
                    <p></p>
                  </div>
                </div>
              )}
            </div>

            {/* old */}
          </div>
        </>
      ) : (
        <h1>{t("nothing here")}</h1>
      )}
    </>
  );
};

export default SingleBet;
