import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//import PropTypes from "prop-types";
import axiosInstance from "../../config";
import "../../styles/MobileMain.css";
import { useHistory } from "react-router-dom";

const ChangeOddsType = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const [currentOdds, setCurrentOdds] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const updateOddsType = () => {
    setLoading(true);
    axiosInstance
      .patch("restricted/oddType/" + currentOdds)
      .then((res) => {
        window.$("#toastSuccess").toast("show");
      })
      .catch((err) => {
        if (err.response) {
          setErrorMessage(err.response.data.message);
        } else {
          setErrorMessage(err.toString());
        }
        window.$("#toastError").toast("show");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let odds = props.history.location.state?.odds;
    setCurrentOdds(odds);
  }, []);

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">
        {t("Change Odds Type")}
        </span>

        <span
          className="header-icon header-icon-1"
          onClick={() => {
            history.goBack();
          }}
        >
          <i className="fas color-white fa-arrow-left color-red-light"></i>
        </span>
      </div>
      <div className="page-title-clear" />
      <div className="page-content">
        <div className="card card-style mt-3 rounded">
          <div className="content">
            <div
              className="d-flex pb-3 pt-3"
              onClick={() => {
                setCurrentOdds("MY");
              }}
            >
              <div className="color-theme">MY</div>
              <div className="ml-auto ml-3">
                {currentOdds === "MY" ? <i className="fa fa-check"></i> : null}
              </div>
            </div>

            <div className="divider" style={{ marginBottom: 0 }}></div>

            <div
              className="d-flex pb-3 pt-3"
              onClick={() => {
                setCurrentOdds("HK");
              }}
            >
              <div className="color-theme">HK</div>
              <div className="ml-auto ml-3">
                {currentOdds === "HK" ? <i className="fa fa-check"></i> : null}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="text-center" style={{ marginTop: 16 }}>
            <div className="spinner-border color-red-dark" role="status">
              <span className="sr-only">{t("Loading")}...</span>
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              updateOddsType();
            }}
            className="btn btn-s btn-full mb-3 ml-3 mr-3 rounded-xs font-900 btn-green"
          >
            {t("Update")}
          </div>
        )}
      </div>
      {/* Page content ends here*/}
      <div
        id="toastError"
        className="toast toast-tiny toast-bottom bg-red-dark fade hide toast-custom"
        data-delay="3000"
        data-autohide="true"
      >
        <i className="fa fa-times mr-3"></i>
        {errorMessage}
      </div>

      <div
        id="toastSuccess"
        className="toast toast-tiny toast-bottom bg-green-dark fade hide toast-custom"
        data-delay="3000"
        data-autohide="true"
      >
        <i className="fa fa-times mr-3"></i>
        {t("Odds type updated")}
      </div>
    </>
  );
};

export default ChangeOddsType;
