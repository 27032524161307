import React from "react";

const EmptyPage = (props) => {
  return (
    <div className="card-center text-center" style={{ color: "#d3d3d3" }}>
      <i className={`fa ${props.icon} fa-9x mb-4`}></i>
      <h4 className="font-24 font-600 mb-2" style={{ color: "#d3d3d3" }}>
        {props.title}
      </h4>
    </div>
  );
};

export default EmptyPage;
