import React from 'react';
import { useTranslation } from "react-i18next";

const OddTable = ({
  htft,
  match,
  getSelectedMenu,
  handleOdd,
}) => {
  const { t } = useTranslation();
  const oddPrefix = htft === 'HT'?'HT':'';
  const htIndex = htft === 'HT'?5:0;
  const ht2Index = htft === 'HT'?1:0;
  return (
    <>
      <div className="content my-0" id={`${match.p}FT`} style={{display: match.sel !== 'HT'?'block':'none'}}>
        <div className="row mb-0 box-heading">
          <div className="col-4 p-1 odd-table-heading">
            {htft} HDP
          </div>
          <div className="col-4 p-1 odd-table-heading">
            {htft} O/U
          </div>
          <div className="col-4 p-1 odd-table-heading">
            {getSelectedMenu('Game') ===
            'Basketball'
              ? htft+' OE'
              : htft+' 1x2'}
          </div>
        </div>
        {/* First Row */}
        <div className="row mb-0">
          <div className="col-4 p-1">
            {match.o1[htIndex+1] !== '' ? ( // check handicap exists
              <button
                className={`box pb-1 w-100 odd-box bg-white`}
                id={match.p + oddPrefix+ `H`}
                onClick={(e) => {
                  handleOdd(match, oddPrefix+'H');
                }}
              >
                <span className="small font-weight-bold">
                  {match.o1[htIndex+0] !== ''
                    ? match.o1[htIndex+0]
                    : match.o2[htIndex+0].replace(
                        '-',
                        ''
                      )}
                </span>{' '}
                <span
                  className={`${
                    (match.o1 &&
                      match.old &&
                      match.old.o1 &&
                      match.o1[htIndex+1] !==
                        match.old.o1[htIndex+1] &&
                      'bg-yellow') ||
                    ''
                  }
                  ${match.o1 && match.o1[htIndex+1] < 0? 'text-danger': ''}
                  `}
                >
                  {match.o1[htIndex+1]}
                </span>
              </button>
            ) : null}
          </div>
          <div className="col-4 p-1">
            {match.o1[htIndex+3] !== '' ? ( // check OU exists
              <button
                className="box pb-1 w-100 odd-box bg-white"
                id={match.p + oddPrefix + `O`}
                onClick={(e) => {
                  handleOdd(match, oddPrefix + 'O');
                }}
              >
                <span className="small font-weight-bold">
                  o {match.o1[htIndex+2]}
                </span>
                <span
                  className={`${
                    (match.o1 &&
                      match.old &&
                      match.old.o1 &&
                      match.o1[htIndex+3] !==
                        match.old.o1[htIndex+3] &&
                      'bg-yellow') ||
                    ''
                  }
                  ${match.o1 && match.o1[htIndex+3] < 0? 'text-danger': ''}
                  `}
                >
                  {match.o1[htIndex+3]}
                </span>
              </button>
            ) : null}
          </div>
          <div className="col-4 p-1">
            {match.o1[htIndex+4] !== '' ? (
              <button
                className="box pb-1 w-100 odd-box bg-white"
                id={
                  getSelectedMenu('Game') ===
                  'Basketball'
                    ? match.p + oddPrefix + 'ODD'
                    : match.p + oddPrefix + 'HOME'
                }
                onClick={(e) => {
                  handleOdd(
                    match,
                    getSelectedMenu(
                      'Game'
                    ) === 'Basketball'
                      ? oddPrefix+'ODD'
                      : oddPrefix+'HOME'
                  );
                }}
              >
                <span className="small font-weight-bold">
                  {getSelectedMenu('Game') ===
                  'Basketball'
                    ? 'odd'
                    : '1'}
                </span>
                <span
                  className={`${
                    (match.o1 &&
                      match.old &&
                      match.old.o1 &&
                      match.o1[htIndex+4] !==
                        match.old.o1[htIndex+4] &&
                      'bg-yellow') ||
                    ''
                  }
                  ${match.o1 && match.o1[htIndex+4] < 0? 'text-danger': ''}
                  `}
                >
                  {match.o1[htIndex+4]}
                </span>
              </button>
            ) : null}
          </div>
        </div>
        {/* Second Row */}
        <div className="row mb-0">
          <div className="col-4 p-1">
            {match.o2[1] !== '' ? ( // check handicap exists
              <button
                className="box pb-1 w-100 odd-box bg-white"
                id={match.p + oddPrefix+`A`}
                onClick={(e) => {
                  handleOdd(match, oddPrefix+'A');
                }}
              >
                <span className="small font-weight-bold">
                  {match.o1[htIndex+0] !== ''
                    ? match.o1[htIndex+0].replace(
                        '-',
                        ''
                      )
                    : match.o2[htIndex+0] !== ''
                    ? '-' + match.o2[htIndex+0]
                    : null}
                </span>{' '}
                <span
                  className={`${
                    (match.o2 &&
                      match.old &&
                      match.old.o2 &&
                      match.o2[htIndex+1] !==
                        match.old.o2[htIndex+1] &&
                      'bg-yellow') ||
                    ''
                  }
                  ${match.o2 && match.o2[htIndex+1] < 0? 'text-danger': ''}
                  `}
                >
                  {match.o2[htIndex+1]}
                </span>
              </button>
            ) : null}
          </div>
          <div className="col-4 p-1">
            {match.o2[htIndex+3] !== '' ? ( // check OU exists
              <button
                className="box pb-1 w-100 odd-box bg-white"
                id={match.p + oddPrefix + `U`}
                onClick={(e) => {
                  handleOdd(match, oddPrefix+'U');
                }}
              >
                <span className="small font-weight-bold">
                  u {match.o1[htIndex+2]}
                </span>
                <span
                  className={`${
                    (match.o2 &&
                      match.old &&
                      match.old.o2 &&
                      match.o2[htIndex+2] !==
                        match.old.o2[htIndex+2] &&
                      'bg-yellow') ||
                    ''
                  }
                  ${match.o2 && match.o2[htIndex+3] < 0? 'text-danger': ''}
                  `}
                >
                  {match.o2[htIndex+3]}
                </span>
              </button>
            ) : null}
          </div>
          <div className="col-4 p-1">
            {match.o2[htIndex+4] !== '' ? (
              <button
                className="box pb-1 w-100 odd-box bg-white"
                id={
                  getSelectedMenu('Game') ===
                  'Basketball'
                    ? match.p + oddPrefix + 'EVEN'
                    : match.p + oddPrefix + 'AWAY'
                }
                onClick={(e) => {
                  handleOdd(
                    match,
                    getSelectedMenu(
                      'Game'
                    ) === 'Basketball'
                      ? oddPrefix+'EVEN'
                      : oddPrefix+'AWAY'
                  );
                }}
              >
                <span className="small font-weight-bold">
                  {getSelectedMenu('Game') ===
                  'Basketball'
                    ? 'even'
                    : '2'}
                </span>
                <span
                  className={`${
                    (match.o2 &&
                      match.old &&
                      match.old.o2 &&
                      match.o2[htIndex+4] !==
                        match.old.o2[htIndex+4] &&
                      'bg-yellow') ||
                    ''
                  }
                  ${match.o2 && match.o2[htIndex+4] < 0? 'text-danger': ''}
                  `}
                >
                  {match.o2[htIndex+4]}
                </span>
              </button>
            ) : null}
          </div>
        </div>
        {/* Third row */}
        {match.tm &&
        match.tm.length === 4 &&
        match.o3 !== undefined &&
        match.o3[ht2Index + 0] !== null && 
        match.o3[ht2Index + 0] !== '' ? (
          <div className="row mb-0">
            <div className="col-4 p-1"></div>
            <div className="col-4 p-1"></div>
            <div className="col-4 p-1">
              {match.o3[ht2Index + 0] !== '' ? (
                <button
                  className="box pb-1 w-100 odd-box bg-white"
                  id={match.p + oddPrefix + `DRAW`}
                  onClick={(e) => {
                    handleOdd(
                      match,
                      oddPrefix + 'DRAW'
                    );
                  }}
                >
                  <span className="small font-weight-bold">
                    X
                  </span>
                  <span
                    className={`${
                      (match.o3 &&
                        match.old &&
                        match.old.o3 &&
                        match.o3[ht2Index + 0] !==
                          match.old.o3[ht2Index + 0] &&
                        'bg-yellow') ||
                      ''
                    }
                    ${match.o3 && match.o3[ht2Index+0] < 0? 'text-danger': ''}
                    `}
                  >
                    {match.o3 && match.o3[ht2Index + 0]}
                  </span>
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default OddTable;