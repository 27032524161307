import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import axiosInstance from '../config';
import axiosInstance2 from '../config2';
import ClipLoader from 'react-spinners/ClipLoader';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [User, setUser] = useState(null);
  let history = useHistory();

  useEffect(() => {
    axiosInstance
      .get('portal/me')
      .then((res) => {
        setUser({
          user: res.data,
        });
        setIsAuthenticated(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        history.push('/');
      });
  }, [history]);

  if (isLoading === true) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40em',
        }}
      >
        <ClipLoader size={130} color={'#123abc'} loading={isLoading} />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} User={User} />
        )
      }
    />
  );
};

export default ProtectedRoute;
