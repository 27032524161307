import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationCN from "./locales/cn/translation.json";
import translationEN from "./locales/en/translation.json";
import translationKH from "./locales/kh/translation.json";
import translationTH from "./locales/th/translation.json";
import translationVN from "./locales/vn/translation.json";
i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: localStorage.getItem("language", "en"),
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },

    resources: {
      cn: {
        translations: translationCN,
      },
      en: {
        translations: translationEN,
      },
      kh: {
        translations: translationKH,
      },
      th: {
        translations: translationTH,
      },
      vn: {
        translations: translationVN,
      },
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
