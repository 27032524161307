import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import PropTypes from "prop-types";
import axiosInstance from '../config';
import axiosInstance2 from '../config2';
import '../styles/Mobile.css';
import { useHistory } from 'react-router-dom';
import { isMobileOnly, isIOS } from 'react-device-detect';
//import LoadingPage from "../components/LoadingPage";
import Me from './main/Me';
import ClipLoader from 'react-spinners/ClipLoader';
// import * as progressier from 'https://progressier.com/client/sw.js?id=5Vl3hLF9tH7aq9iuN8b7';

let deferredPrompt;
const Login = () => {
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const REACT_APP_DESKTOP_URL = process.env.REACT_APP_DESKTOP_URL;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [installable, setInstallable] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [setting, setSetting] = useState([]);

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };
  const getDomainName = () => {
    return window.location.hostname;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const login = document.querySelector('#login-form');
    const username = login['username'].value;
    const password = login['password'].value;
    if (username === '') {
      setErrorMessage(t('Username cannot be empty'));
      window.$('#toastLogin').toast('show');
    } else if (password === '') {
      setErrorMessage(t('Password cannot be empty'));
      window.$('#toastLogin').toast('show');
    } else {
      setLoading(true);
      const data = {
        username: username,
        password: password,
      };
      axiosInstance2
        .post('authenticate', data)
        .then((res) => {
          localStorage.setItem('token', res.data.jwttoken);
          localStorage.setItem('menu', '');
          // password expiry
          axiosInstance2
            .get('portal/checkPwdExpiry')
            .then((response) => {
              if (response.status !== 200) {
                history.push('/pwdexpiry');
              }
            })
            .catch((err) => {
              if (
                err.response.status === 403 &&
                err.response.data.message === 'AP003'
              ) {
                history.push('/pwdexpiry');
              }
            });
          history.push('/odds');
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.message === 'AUTH005') {
              setErrorMessage(
                t('Your account is locked. Please contact your agent.')
              );
            } else {
              setErrorMessage(err.response.data.message);
            }
          } else {
            setErrorMessage(err.toString());
          }
          window.$('#toastLogin').toast('show');
          login['username'].value = '';
          login['password'].value = '';
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    localStorage.setItem('deviceType', navigator.platform);
    const lang = getParameterByName('lang');
    var fromDesktop = false;
    if(lang) {
      localStorage.setItem('language', lang);
      i18n.changeLanguage(lang);
      fromDesktop = true;
    }
  
    let token = getParameterByName('token');
    if (token && token.length > 0) {
      axiosInstance2
        .get('public/switch/validate?secret=' + token)
        .then((res) => {
          localStorage.setItem('token', res.data.jwttoken);
          localStorage.setItem('menu', '');
          history.replace('/odds');
        })
        .catch((err) => {
          history.replace('/odds');
          setIsAuthenticated(false);
          setIsLoading(false);
        });
    } else if(fromDesktop) {
      history.replace('/odds');
    } else {
      axiosInstance2
        .get('portal/me')
        .then((res) => {
          setIsAuthenticated(true);
          setSetting(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsAuthenticated(false);
          setIsLoading(false);
        });
    }
  }, [history]);

  const handleInstall = (e) => {
    /*
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    }); 
    if (isIOS) {
      if (getPWADisplayMode() === 'browser') {
        window.progressier.promote();
      }
    }*/
  };
  return (
    <>
      {isLoading === true ? (
        <>
          <div
            style={{
              height: '40em',
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <ClipLoader size={130} color={'#123abc'} loading={isLoading} />
          </div>
        </>
      ) : (
        <>
          {isAuthenticated === false ? (
            <>
              <div className="page-header bg-danger">
                <div className="row">
                  <div className="col-12 text-center py-2">
                    <div className="login-title text-light">{t('Sign In')}</div>
                  </div>
                </div>
              </div>
              <div className="page-content login-bg header-clear-medium">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-8 col-md-6">
                    <form action="" onSubmit={handleSubmit} id="login-form">
                      <div className="login-box p-4 mx-4">
                        <img
                          alt="Logo"
                          className="login-logo"
                          src={require('../img/Soccer.png')}
                        />
                        <div className="input-style has-icon input-style-1 mt-4 input-required">
                          <i className="input-icon fa fa-user" />
                          <span>{t('Username')}</span>
                          <em>({t('required')})</em>
                          <input
                            type="name"
                            placeholder={t('Username')}
                            id="username"
                          />
                        </div>
                        <div className="input-style has-icon input-style-1 input-required">
                          <i className="input-icon fa fa-lock" />
                          <span>{t('Password')}</span>
                          <em>({t('required')})</em>
                          <input
                            type="password"
                            placeholder={t('Password')}
                            id="password"
                          />
                        </div>
                        {loading ? (
                          <div
                            className="text-center"
                            style={{ marginTop: 16 }}
                          >
                            <div
                              className="spinner-border color-red-dark"
                              role="status"
                            >
                              <span className="sr-only">{t('Loading')}...</span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <button className="btn btn-block py-1 font-600 font-13 bg-green-dark mt-4 rounded">
                              {t('Sign In')}
                            </button>
                            {installable && isIOS ? (
                              <>
                                <button
                                  className="progressier-install-button btn btn-block bg-blue font-600 py-1 install-button font-13 mt-2 rounded"
                                  data-behavior="disable"
                                  data-icons="false"
                                  data-install="Install App"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </>
                            ) : null}
                            {!isIOS &&
                            getDomainName() !==
                              process.env.REACT_APP_MOBILE_APK_DOMAIN ? (
                              <>
                                <button
                                  className="btn btn-block p-0 install-button font-13 mt-2 rounded"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                      process.env.REACT_APP_MOBILE_APK_URL
                                    );
                                  }}
                                >
                                  <img
                                    className="download-image"
                                    src={require('../img/downloadbannergif.gif')}
                                  />
                                </button>
                              </>
                            ) : null}
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                <div className="col-12">
                <div className="card card-style pt-2 mr-4 ml-4 rounded">
                <div className="content">
                  <div
                    className="d-flex pb-2"
                    onClick={() => {
                      let path = '/mobile/me/change-language';
                      history.push({
                        pathname: path,
                      });
                    }}
                  >
                    <div className="color-theme"> 
                      <i className="fas fa-language icon-color-theme icon mr-2"></i> 
                      {t("Language")}
                    </div>
                    <div className="ml-auto">
                      <div className="color-theme">{(localStorage.getItem("language") || "EN").toUpperCase()}</div>
                    </div>
                    
                    <div className="ml-3">
                      <i className="fa fa-chevron-right" />
                    </div>
                  </div>
                </div>
                </div>
                </div>
                </div>
                <div className="row text-center">
                  <div className="col-12">
                    <div className="p-2 mx-4">
                      <p className="mb-1">{t('Supported browsers')}:</p>
                      <img
                        className="browsers"
                        alt="Google Chrome"
                        src={require('../img/Icons/chrome.svg')}
                      />
                      <img
                        className="browsers"
                        alt="Safari"
                        src={require('../img/Icons/safari.svg')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-12">
                    <button
                      className="btn p-2 border-blue-dark mx-4"
                      onClick={() => {
                        window.location.href = `${REACT_APP_DESKTOP_URL}&lang=${localStorage.getItem('language') || 'en'}`;
                      }}
                    >
                      <img
                        className="browsers icon-blue"
                        alt="Desktop"
                        src={require('../img/Icons/desktop.svg')}
                      />
                      <span className="font-14 color-blue mr-1">
                        {t('Switch to Desktop')}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                id="toastLogin"
                className="toast toast-tiny toast-bottom bg-red-dark fade hide toast-custom"
                data-delay="3000"
                data-autohide="true"
              >
                <i className="fa fa-times mr-3" />
                {errorMessage}
              </div>
            </>
          ) : (
            <Me
              deferredPrompt={deferredPrompt}
              setting={setting}
              installable={installable}
              setInstallable={setInstallable}
              handleInstall={handleInstall}
            />
          )}
        </>
      )}
    </>
  );
};

export default Login;
