import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
//import { useHistory } from 'react-router-dom';

/*
const useStyles = makeStyles((theme) => ({
  me: {
    minWidth: '50px',
  },
})); */

const Footer = () => {
  //const history = useHistory();
  //const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div id="footer-bar" className="footer-bar-6">
      <Link
        to={window.location.pathname === '/mobile/news' ? '#' : '/mobile/news'}
        className={`${
          window.location.pathname === '/mobile/news' ? ' active-menu' : ''
        }`}
      >
        <i className="fa fa-newspaper" />
        <span>{t("News")}</span>
      </Link>
      <Link
        to={
          window.location.pathname === '/mobile/result' ? '#' : '/mobile/result'
        }
        className={`${
          window.location.pathname === '/mobile/result' ? ' active-menu' : ''
        }`}
      >
        <i className="fa fa-chart-bar" />
        <span>{t("Results")}</span>
      </Link>
      <Link to="/odds" className="circle-nav">
        <i className="fa fa-futbol" />
        <span>{t("Odds")}</span>
        <strong>
          <u></u>
        </strong>
      </Link>
      <Link
        to={
          window.location.pathname === '/mobile/statement'
            ? '#'
            : '/mobile/statement'
        }
        className={`${
          window.location.pathname === '/mobile/statement' ? ' active-menu' : ''
        }`}
      >
        <i className="fa fa-clock" />
        <span>{t("Statement")}</span>
      </Link>
      <Link
        to="/"
        className={`${
          window.location.pathname === '/mobile/me' || window.location.pathname === '/' ? ` active-menu ` : ''
        }`}
      >
        <i className="fa fa-user" />
        <span>{t("Me")}</span>
      </Link>
    </div>
  );
};

export default Footer;
