import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import axiosInstance from '../../config';
import '../../styles/MobileMain.css';
import { useHistory } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import Footer from '../../components/Footer';
import moment from 'moment';

const Me = ({ setting, installable, handleInstall }) => {
  let history = useHistory();
  const { t } = useTranslation();
  const [loadingLogout, setLoadingLogout] = useState(false);
  const [loadingOddsType, setLoadingOddsType] = useState(false);

  const handleLogout = () => {
    setLoadingLogout(true);
    axiosInstance
      .post('token/invalidate')
      .then((res) => {
        var lang = localStorage.getItem('language') || 'en';
        localStorage.clear();
        localStorage.setItem('language', lang);
        window.location.href = '/';
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingLogout(false);
      });
  };

  const changeOddsType = (type) => {
    setLoadingOddsType(true);
    axiosInstance
      .patch('restricted/oddType/' + type)
      .then((res) => {
        // getMe();
      })
      .catch((err) => console.log(err));
  };

  const handleSwitch = () => {
    var lang = localStorage.getItem('language') || 'en';
    axiosInstance
      .post('token/mobile/switch')
      .then((res) => {
        localStorage.clear();
        localStorage.setItem('language', lang);
        window.location.href = `${process.env.REACT_APP_DESKTOP_URL}&token=${res.data}&lang=${lang}`;
      })
      .catch((err) => {
        console.log(err);
        window.location.href = `${process.env.REACT_APP_DESKTOP_URL}&lang=${lang}`;
      })
  }

  const showOddsTypeDialog = (show) => {
    if (show) {
      window.$('#menu-odds-type').showMenu();
    } else {
      window.$('#menu-odds-type').hideMenu();
    }
  };

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">{t("My Account")}</span>
      </div>

      {/* footer start */}
      <Footer />
      {/* footer end */}

      <div className="page-title-clear" />
      <div className="page-content">
        <div className="card content mt-3 mb-3 rounded">
          <div className="content">
            <h4 className="mb-3 mt-1">
              <img
                src={require('../../img/Icons/user-20.svg')}
                alt=""
                className="icon mr-2"
              />
              {t("Welcome")}, {setting.username}
            </h4>

            <div className="d-flex mb-3">
              <div className="color-theme">{t("Balance")}</div>
              <div className="ml-auto ml-3">
                <div className="font-weight-bold">
                  {setting.currency}{' '}
                  <CurrencyFormat
                    value={setting.availableBalance}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div className="color-theme">{t("Last Login")}</div>
              <div className="ml-auto ml-3">
                <div>{moment(setting.lastLogin).format('DD MMM h:mm a')}</div>
              </div>
            </div>
          </div>
        </div>
        {installable && (
          <>
            <button
              className="progressier-install-button btn btn-block bg-blue font-weight-bold py-1 install-button font-13 mt-2 rounded w-75 mx-auto "
              data-behavior="disable"
              data-icons="false"
              data-install={t("Install the app")}
              // data-installed="Install App Progressier"
            />
          </>
        )}

        <div className="card card-style mb-3 rounded">
          <div className="content">
            <h4 className="mb-3 mt-1">{t("Display Settings")}</h4>

            <div
              className="d-flex pb-2"
              onClick={() => {
                // showOddsTypeDialog(true);
                let path = '/mobile/me/change-odds-type';
                history.push({
                  pathname: path,
                  state: {
                    odds: setting.oddType,
                  },
                });
              }}
            >
              <div className="color-theme"> 
                <i className="fas fa-globe icon-color-theme icon mr-2"></i> 
                {t("Odds Type")}
              </div>
              <div className="ml-auto">
                <div className="color-theme">{setting.oddType}</div>
              </div>
              {loadingOddsType ? (
                <div className="ml-3">
                  <div
                    className="spinner-border color-red-dark"
                    role="status"
                    style={{ width: 16, height: 16 }}
                  >
                    <span className="sr-only">{t("Loading")}...</span>
                  </div>
                </div>
              ) : (
                <div className="ml-3">
                  <i className="fa fa-chevron-right" />
                </div>
              )}
            </div>
            <div
              className="d-flex pb-2"
              onClick={() => {
                let path = '/mobile/me/change-language';
                history.push({
                  pathname: path,
                });
              }}
            >
              <div className="color-theme"> 
                <i className="fas fa-language icon-color-theme icon mr-2"></i> 
                {t("Language")}
              </div>
              <div className="ml-auto">
                <div className="color-theme">{(localStorage.getItem("language") || "EN").toUpperCase()}</div>
              </div>
              
              <div className="ml-3">
                <i className="fa fa-chevron-right" />
              </div>
            </div>
            <div
              className="d-flex pb-2 pt-2"
              onClick={() => {
                handleSwitch();
                // window.location.href = process.env.REACT_APP_DESKTOP_URL;
              }}
            >
              <div className="color-theme">
                <i className="fas fa-desktop icon-color-theme icon mr-2"></i>
                {t("Switch to Desktop")}
              </div>
              <div className="ml-auto ml-3">
                <i className="fa fa-chevron-right" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card card-style mb-3 rounded">
          <div className="content">
            <h4 className="mb-4 mt-1">Cash</h4>
          </div>
        </div> */}

        <div className="card card-style mb-3 rounded">
          <div className="content">
            {/* <div
              className="d-flex pt-2 pb-2"
              onClick={() => {
                let path = '/mobile/me/withdraw';
                history.push(path);
              }}
            >
              <div className="color-theme">
                <img
                  src={require('../../img/Icons/banknote-15.svg')}
                  alt=""
                  className="icon-color-theme icon mr-2"
                />
                {t("Withdraw")}
              </div>
              <div className="ml-auto ml-3">
                <i className="fa fa-chevron-right" />
              </div>
            </div> */}
            <div
              className="d-flex pb-2"
              onClick={() => {
                let path = '/mobile/me/cash';
                history.push(path);
              }}
            >
              <div className="color-theme">
                <img
                  src={require('../../img/Icons/banknote-18.svg')}
                  alt=""
                  className="icon-color-theme icon mr-2"
                />
                {t("Cash")}
              </div>
              <div className="ml-auto ml-3">
                <i className="fa fa-chevron-right" />
              </div>
            </div>
          </div>
        </div>

        <div className="card card-style mb-3 rounded">
          <div className="content">
            <div
              className="d-flex pb-2 pt-2"
              onClick={() => {
                let path = '/mobile/me/change-password';
                history.push(path);
              }}
            >
              <div className="color-theme">
                <img
                  src={require('../../img/Icons/key-1.svg')}
                  alt=""
                  className="icon-color-theme icon mr-2"
                />
                {t("Change Password")}
              </div>
              <div className="ml-auto ml-3">
                <i className="fa fa-chevron-right" />
              </div>
            </div>
            <div
              className="d-flex pb-2 pt-2"
              onClick={() => {
                handleLogout();
              }}
            >
              <div className="color-theme">
                <img
                  src={require('../../img/Icons/log-out-3.svg')}
                  alt=""
                  className="icon-color-theme icon mr-2"
                />
                {t("Logout")}
              </div>
              {loadingLogout ? (
                <div className="ml-auto">
                  <div
                    className="spinner-border color-red-dark"
                    role="status"
                    style={{ width: 16, height: 16 }}
                  >
                    <span className="sr-only">{t("Loading")}...</span>
                  </div>
                </div>
              ) : (
                <div className="ml-auto ml-3">
                  <i className="fa fa-chevron-right" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Page content ends here*/}

      {/* action sheet odds type */}
      <div
        id="menu-odds-type"
        className="menu menu-box-bottom rounded-m"
        data-menu-height="230"
        data-menu-effect="menu-over"
        style={{ height: 230, display: 'block' }}
      >
        <div className="menu-title">
          <h1 className="font-18">{t("Change Odds type")}</h1>
          <div
            className="me-close-btn"
            onClick={() => {
              showOddsTypeDialog(false);
            }}
          >
            <i className="fa fa-times-circle" style={{ marginTop: 8 }} />
          </div>
        </div>
        <div className="mr-3 ml-3 mt-3">
          <div className="list-group list-custom-small">
            <div
              className="d-flex pb-1 pt-1"
              onClick={() => {
                changeOddsType('MY');
                showOddsTypeDialog(false);
              }}
            >
              <div className="color-theme" style={{ lineHeight: '50px' }}>
              {t("MY")}
              </div>
              <div className="ml-auto ml-3">
                <i className="fa fa-chevron-right" />
              </div>
            </div>
            <div
              className="d-flex pb-1 pt-1"
              onClick={() => {
                changeOddsType('HK');
                showOddsTypeDialog(false);
              }}
            >
              <div className="color-theme" style={{ lineHeight: '50px' }}>
              {t("HK")}
              </div>
              <div className="ml-auto ml-3">
                <i className="fa fa-chevron-right" />
              </div>
            </div>
          </div>
          <div className="clear" />
        </div>
      </div>
      {/* end action sheet odds type */}
    </>
  );
};

export default Me;
