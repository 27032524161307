import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import Modal from '@material-ui/core/Modal';
import '../styles/MobileMain.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import lifecycle from 'react-pure-lifecycle';
import SingleBet from '../pages/SingleBet';
import Parley from '../pages/Parley';
import AliceCarousel from 'react-alice-carousel';
import OddTable from './OddTable';
import SuccessParlay from './SuccessParlay';
import SuccessSingle from './SuccessSingle';
import Game from './Game';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Lifecycle methods
const componentDidUpdate = (props, previousProps) => {
  window.init_template();
};

const methods = {
  componentDidUpdate,
};

const useStyles = makeStyles((theme) => ({
  redBox: {
    color: '#fff',
    paddingLeft: '0.1rem',
    paddingRight: '0.1rem',
    backgroundColor: '#D61C15',
    fontSize: '11px',
  },
  Modal: {
    margin: '0 auto',
    width: '100vw',
    backgroundColor: '#fff',
    borderRadius: '4px',
    height: '100%',
    overflowY: 'auto',
  },
  // bet modal style
  betModal: {
    margin: '0 auto',
    width: '85vw',
    maxWidth: '480px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  betModalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '75%',
    display: 'block',
    margin: 'auto',
  },
  // parlay modal
  parlayModal: {
    margin: '0 auto',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  parlayModalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    width: '80%',
    display: 'block',
    margin: 'auto',
  },
  // game modal
  gameModal: {
    height: '100%',
    display: 'block',
    margin: 'auto',
    overflowY: 'scroll',
  },
  // parlay successs modal
  parlaySuccessModal: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '100%',
    display: 'block',
    margin: 'auto',
  },
}));
const TableInfo = ({
  matches,
  getSelectedMenu,
  onlyFav,
  sortOrder,
  parlayBet,
  setParlayBet,
  setParlayModal,
  parlayModal,
  loadSingleMatch,
  refreshLeague,
  removeSingleMatch,
  clearOddSelection,
  expandCollapseLeague,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  // to display odd error
  const [oddError, setOddError] = useState(false);
  // to display odd is updating
  const [oddUpdating, setOddUpdating] = useState(false);
  // to display game modal
  const [gameModal, setGameModal] = useState(false);
  const [gameSelected, setGameSelected] = useState('');

  // parlay odds updating
  // to display odd error
  const [, setOddErrorP] = useState(false);
  // to display odd is updating
  const [, setOddUpdatingP] = useState(false);
  const [addParlay, setAddParlay] = useState(false);

  // modal
  const [open, setOpen] = useState(false);
  const [singleBet, setSingleBet] = useState();
  // modal for bet succes
  const [betSuccess, setBetSuccess] = useState();
  const [betSuccessModal, setBetSuccessModal] = useState(false);
  const [parlaySuccess, setParlaySuccess] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const selectedBoxStyle = 'box pb-1 w-100 odd-box bg-white odd-box-selected';
  const unselectedBoxStyle = 'box pb-1 w-100 odd-box bg-white';

  // refresh parlaySelection
  const refreshParlaySelection = () => {
    clearOddSelection();
    if (parlayBet && parlayBet.parlays) {
      for (let i = 0; i < parlayBet.parlays.length; i++) {
        let element = document.getElementById(
          parlayBet.parlays[i].odd + parlayBet.parlays[i].pick
        );
        if (element) {
          element.className = selectedBoxStyle;
        }
      }
    }
  };

  // mobile handleOdd
  const handleOdd = (match, pick, suffix) => {
    setOddError(false);
    setOddUpdating(false);
    setOddErrorP(false);
    setOddUpdating(false);
    setOddUpdatingP(false);

    var compId = match.p;
    if (suffix && suffix === 'm') compId = suffix;

    let element;

    if (getSelectedMenu('Parlay') === 'Parlay') {
      const parlayBody = {
        odd: match.p,
        pick: pick,
        quote: parlayBet !== undefined ? parlayBet.quote : '',
      };
      axiosInstance
        .post('restricted/parlay', qs.stringify(parlayBody))
        .then((res) => {
          var eleList = document.querySelectorAll(
            'button[id*="' + compId + '"]'
          );
          if (eleList) {
            for (let k = 0; k < eleList.length; k++) {
              var ele = eleList[k];
              ele.className = unselectedBoxStyle;
            }
          }
          let found = false;
          res.data.parlays.forEach((parlay) => {
            if (parlay.odd === match.p) {
              element = document.getElementById(
                match.p + pick + (suffix ? suffix : '')
              );
              if (element) element.className = selectedBoxStyle;
              found = true;
            }
          });

          if (found === false) {
            setErrorMessage('Odds is Updating');
            window.$('#toastLogin').toast('show');
          }

          if (parlayBet !== undefined) {
            setAddParlay(true);
          }
          setParlayBet(res.data);
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage('Odds is Updating');
          if (element) element.className = unselectedBoxStyle;
          window.$('#toastLogin').toast('show');
        });
    } else {
      const requestBody = {
        odd: match.p,
        pick: pick,
      };
      axiosInstance
        .post('restricted/quote', qs.stringify(requestBody))
        .then((res) => {
          setSingleBet(res.data);
          setOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setOddError(true);
          setOddErrorP(true);
          setSingleBet('');
          setErrorMessage('Odds is Updating');
          window.$('#toastLogin').toast('show');
        });
    }
  };

  // remove parlay from quote
  const handleRemove = (quote, odd) => {
    clearOddSelection(odd);
    const requestBody = {
      id: quote,
      quote: parlayBet.quote,
    };
    axiosInstance
      .post('restricted/parlay/remove', qs.stringify(requestBody))
      .then((res) => {
        setParlayBet(res.data);
        if (res.data.parlays.length === 0) {
          setParlayModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const favMatch = (match, action) => {
    axiosInstance
      .post(
        'restricted/favorite',
        qs.stringify({
          add: action,
          match: match,
        })
      )
      .then((res) => {
        // TODO: Show notification toaster
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push('/');
        }
      });
  };

  const favLeague = (league, action) => {
    axiosInstance
      .post(
        'restricted/favorite',
        qs.stringify({
          add: action,
          comp: league,
        })
      )
      .then((res) => {
        // TODO: Show notification toaster
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push('/');
        }
      });
  };

  const getMinutes = (match) => {
    if (match.s[3] === 1 && match.s[2] > 45) return '45+ mins';
    else if (match.s[3] === 2 && match.s[2] > 90) return '90+ mins';
    else if (match.s[3] === 1 && match.s[2] <= 45) return match.s[2] + ' mins';
    else if (match.s[3] === 2 && match.s[2] <= 90) return match.s[2] + ' mins';
    else if (match.s[3] === 5) return 'HT';
    return '';
  };

  const [, setUpdateComponent] = useState(new Date());

  return (
    <>
      <div
        id="toastLogin"
        className="toast toast-tiny toast-bottom bg-red-dark fade hide toast-custom"
        data-delay="3000"
        data-autohide="true"
      >
        <i className="fa fa-times mr-3" />
        {errorMessage}
      </div>
      {/* modal for single bet */}
      {getSelectedMenu('Parlay') !== 'Parlay' ? (
        <Modal
          open={open}
          onClose={() => {
            clearOddSelection();
            setOpen(false);
          }}
          aria-labelledby="single-bet-modal"
          aria-describedby="modal used for single bet display"
          className={`${classes.betModalStyle}  
           `}
          id="modal-single"
        >
          <div className={classes.betModal}>
            <SingleBet
              singleBet={singleBet}
              setSingleBet={setSingleBet}
              setOpen={setOpen}
              betSuccess={betSuccess}
              setBetSuccess={setBetSuccess}
              setBetSuccessModal={setBetSuccessModal}
              oddError={oddError}
              setOddError={setOddError}
              setOddUpdating={setOddUpdating}
              oddUpdating={oddUpdating}
              clearOddSelection={clearOddSelection}
            />
          </div>
        </Modal>
      ) : null}

      {/* the parlay bubble */}
      {getSelectedMenu('Parlay') === 'Parlay' &&
      parlayBet !== undefined &&
      parlayBet.parlays.length !== 0 ? (
        <div
          className={
            parlayBet.parlays.length !== 0
              ? `ticket d-flex justify-content-center align-items-center glow-circle `
              : `ticket d-flex justify-content-center align-items-center`
          }
          onClick={() => setParlayModal(true)}
        >
          <i className="fas fa-ticket-alt text-success fa-2x" />
          {parlayBet.parlays.length !== 0 ? (
            <div className="match-length d-flex justify-content-center align-items-center">
              <p className="m-0 text-white text-center">
                {parlayBet.parlays.length}
              </p>
            </div>
          ) : null}
        </div>
      ) : null}

      {/* modal for parlay */}
      <Modal
        open={parlayModal}
        onClose={() => {
          setParlayModal(false);
        }}
        aria-labelledby="parlay-modal"
        aria-describedby="modal used for parlay bet display"
        className={`${classes.parlayModalStyle}`}
        id="modal-parlay"
      >
        <div className={classes.parlayModal}>
          <Parley
            setParlayModal={setParlayModal}
            parlayBet={parlayBet}
            setParlayBet={setParlayBet}
            parlaySuccess={parlaySuccess}
            setParlaySuccess={setParlaySuccess}
            setBetSuccessModal={setBetSuccessModal}
            oddError={oddError}
            setOddError={setOddError}
            oddUpdating={oddUpdating}
            setOddUpdating={setOddUpdating}
            addParlay={addParlay}
            setAddParlay={setAddParlay}
            handleRemove={handleRemove}
            clearOddSelection={clearOddSelection}
          />
        </div>
      </Modal>

      {/* modal for game */}
      <Modal
        open={gameModal}
        onClose={() => setGameModal(false)}
        aria-labelledby="game-modal"
        aria-describedby="modal used for game page"
        className={`${classes.gameModal}  animate__animated animate__slideInUp 	
          `}
        id="modal-game"
      >
        <div className={classes.Modal}>
          <Game
            setGameModal={setGameModal}
            id={gameSelected}
            handleOdd={handleOdd}
            parlayBet={parlayBet}
            refreshParlaySelection={refreshParlaySelection}
            parlayFlag={getSelectedMenu('Parlay') === 'Parlay' ? 'Y' : 'N'}
            setParlayModal={setParlayModal}
          />
        </div>
      </Modal>

      {/* code for  bet success modal */}
      <Modal
        open={betSuccessModal}
        onClose={() => {
          clearOddSelection();
          setBetSuccessModal(false);
        }}
        aria-labelledby="single-bet-modal"
        aria-describedby="modal used for single bet display"
        className={
          getSelectedMenu('Parlay') === 'Parlay'
            ? classes.parlaySuccessModal
            : classes.betModalStyle
        }
      >
        <div className={classes.parlayModalStyle}>
          {betSuccess !== undefined &&
          getSelectedMenu('Parlay') !== 'Parlay' ? (
            <>
              <SuccessSingle
                betSuccess={betSuccess}
                setBetSuccessModal={setBetSuccessModal}
              />
            </>
          ) : (
            <>
              {/* parlay bet succesful modal */}
              {getSelectedMenu('Parlay') === 'Parlay' &&
              parlaySuccess !== undefined ? (
                <SuccessParlay
                  parlaySuccess={parlaySuccess}
                  setBetSuccessModal={setBetSuccessModal}
                />
              ) : null}
            </>
          )}
        </div>
      </Modal>

      {/* code for odds table */}

      <div className="accordion mt-2" id="accordion-6">
        {matches && matches.length === 0 ? (
          <p className="text-body text-center my-3">
            {t('No matches at the moment')}
          </p>
        ) : null}

        {matches &&
          matches.map((tmatch, index) => (
            <React.Fragment key={index}>
              <div className="card ml-1 mr-1 mb-2">
                {tmatch.l && tmatch.l !== 'Total Corners' && tmatch.l !== 'Total Bookings' ? (
                  <>
                    <div className="btn-group" role="group">
                      {tmatch.lf && tmatch.lf.length > 1 && tmatch.lf[1] ? (
                        <button
                          onClick={() => {
                            tmatch.lf[1] = 0;
                            setUpdateComponent(new Date());
                            favLeague(tmatch.lf[0], 0);
                          }}
                          type="button"
                          className="btn py-1 btn-secondary border-0"
                        >
                          <i className="fa fa-star font-weight-bold" />
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            tmatch.lf[1] = 1;
                            setUpdateComponent(new Date());
                            favLeague(tmatch.lf[0], 1);
                          }}
                          type="button"
                          className="btn py-1 btn-secondary border-0"
                        >
                          <i className="fa fa-star font-weight-normal" />
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => {
                          return expandCollapseLeague(tmatch);
                        }}
                        className="btn py-1 btn-secondary px-0 accordion-btn border-0"
                      >
                        {tmatch.l} ({tmatch.matches.length}){' '}
                      </button>
                      <i
                        onClick={() => {
                          tmatch['isSpin'] = true;
                          return refreshLeague(tmatch);
                        }}
                        className={`fa fa-sync ${
                          tmatch['isSpin'] ? 'fa-spin' : ''
                        } font-13 text-danger px-2 accordion-icon`}
                      />
                    </div>

                    {tmatch.matches.map((match, mindex) => (
                      <React.Fragment key={mindex}>
                        <button
                          type="button"
                          className={`btn hide-collapse ${
                            match.o1 ? '' : 'collapsed '
                          }p-0 col-12 accordion-btn border-0`}
                          data-toggle="collapse"
                          data-target={`#collapse${match.p}`}
                          onClick={() => loadSingleMatch(match)}
                        >
                          <div className="row align-items-center mb-0">
                            <div className="col-3 text-center px-0">
                              <span className="d-block text-danger">
                                {match.tm === undefined ? null : match.s ===
                                  undefined ? (
                                  <>{match.d && match.d[0]}</>
                                ) : (
                                  <>
                                    {match.s[0]}-{match.s[1]}
                                  </>
                                )}
                              </span>
                              <span className="text-primary">
                                {match.tm === undefined ? null : match.s ===
                                  undefined ? (
                                  <>{match.d && match.d[1]}</>
                                ) : (
                                  <>{getMinutes(match)}</>
                                )}
                              </span>
                            </div>
                            <div className="col-7 px-0">
                              <span
                                className={`d-block ${
                                  match.tm && match.tm[3] && match.tm[3] === '1'
                                    ? 'text-danger'
                                    : ''
                                }`}
                              >
                                {match.tm && match.tm[0]}
                                {match.s !== undefined &&
                                match.s.length > 4 &&
                                match.s[4] > 0 ? (
                                  <span className="badge badge-danger ml-1">
                                    {match.s[4]}
                                  </span>
                                ) : null}
                              </span>
                              <span
                                className={`${
                                  match.tm && match.tm[3] && match.tm[3] === '2'
                                    ? 'text-danger'
                                    : ''
                                }`}
                              >
                                {match.tm && match.tm[1]}{' '}
                                {match.s !== undefined &&
                                match.s.length > 4 &&
                                match.s[5] > 0 ? (
                                  <span className="badge badge-danger ml-1">
                                    {match.s[5]}
                                  </span>
                                ) : null}
                              </span>
                            </div>
                            <div className="col-2">
                              <i
                                onClick={() => {
                                  // return refreshLeague(tmatch);
                                }}
                                className="fa fa-chevron-down font-13 accordion-icon"
                              />
                            </div>
                          </div>
                        </button>

                        <div
                          id={`collapse${match.p}`}
                          className={`collapse bg-theme${
                            match.o1 ? ' show' : ''
                          }`}
                        >
                          <div className="color-theme box-parent">
                            {match.o1 ? (
                              <div className="row mb-0 px-3 active-odd">
                                <div className="col-11">
                                  <div className="row mb-0 bg-white">
                                    <div className="col-5">
                                      <div className="row mb-0 box-heading">
                                        <div className="col-4 py-1 px-0 text-danger font-weight-bold">
                                          {match.tm ===
                                          undefined ? null : match.s ===
                                            undefined ? (
                                            <>{match.d && match.d[0]}</>
                                          ) : (
                                            <>
                                              {match.s[0]}-{match.s[1]}
                                            </>
                                          )}
                                        </div>
                                        <div className="col-6 py-1 px-0 text-primary">
                                          {match.tm ===
                                          undefined ? null : match.s ===
                                            undefined ? (
                                            <>{match.d && match.d[1]}</>
                                          ) : (
                                            <>{getMinutes(match)}</>
                                          )}
                                        </div>
                                        <div className="col-2 py-0 px-0 star-rating">
                                          {match.l !== 'Total Corners' &&
                                          match.l !== 'Total Bookings' &&
                                          match.mf &&
                                          match.mf.length > 1 &&
                                          match.mf[1] ? (
                                            <button
                                              onClick={() => {
                                                match.mf[1] = 0;
                                                if (tmatch.p === match.p) {
                                                  tmatch.mf[1] = 0;
                                                }
                                                setUpdateComponent(new Date());
                                                favMatch(match.mf[0], 0);
                                              }}
                                              type="button"
                                              className="btn btn-link border-0"
                                            >
                                              <i className="fa fa-star font-weight-bold" />
                                            </button>
                                          ) : (match.l !== 'Total Corners' && match.l !== 'Total Bookings') ? (
                                            <button
                                              onClick={() => {
                                                match.mf[1] = 1;
                                                if (tmatch.p === match.p) {
                                                  tmatch.mf[1] = 1;
                                                }
                                                setUpdateComponent(new Date());
                                                favMatch(match.mf[0], 1);
                                              }}
                                              type="button"
                                              className="btn btn-link border-0"
                                            >
                                              <i className="fa fa-star font-weight-normal" />
                                            </button>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="row mb-0">
                                        <div
                                          className={`col-12 px-1 box-label ${
                                            match.tm &&
                                            match.tm[3] &&
                                            match.tm[3] === '1'
                                              ? 'text-danger'
                                              : ''
                                          }`}
                                        >
                                          {match.tm && match.tm[0]}
                                          {match.s !== undefined &&
                                          match.s.length > 4 &&
                                          match.s[4] > 0 ? (
                                            <span className="badge badge-danger ml-1">
                                              {match.s[4]}
                                            </span>
                                          ) : null}
                                        </div>
                                        <div
                                          className={`col-12 px-1 box-label ${
                                            match.tm &&
                                            match.tm[3] &&
                                            match.tm[3] === '2'
                                              ? 'text-danger'
                                              : ''
                                          }`}
                                        >
                                          {match.tm && match.tm[1]}{' '}
                                          {match.s !== undefined &&
                                          match.s.length > 4 &&
                                          match.s[5] > 0 ? (
                                            <span className="badge badge-danger ml-1">
                                              {match.s[5]}
                                            </span>
                                          ) : null}
                                        </div>
                                        {match.tm &&
                                        match.tm.length === 4 &&
                                        match.o3 !== undefined &&
                                        match.o3[0] !== '' ? (
                                          <>
                                            <div className="col-12 px-1 box-label text-primary flex-end">
                                              {match.tm[2]}
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                    {/* <div className="col-7 p-0 pb-0 slider-div"> */}
                                    <div
                                      id={`carousell_${match.p}`}
                                      className="col-7 p-0 pb-0 slider-div"
                                    >
                                      {match.o1 !== undefined &&
                                      (match.o1[5] !== '' ||
                                        match.o1[7] !== '') ? (
                                        <AliceCarousel
                                          animationDuration={100}
                                          disableButtonsControls
                                        >
                                          <OddTable
                                            htft="FT"
                                            match={match}
                                            getSelectedMenu={getSelectedMenu}
                                            handleOdd={handleOdd}
                                          />
                                          <OddTable
                                            htft="HT"
                                            match={match}
                                            getSelectedMenu={getSelectedMenu}
                                            handleOdd={handleOdd}
                                          />
                                        </AliceCarousel>
                                      ) : (
                                        <OddTable
                                          htft="FT"
                                          match={match}
                                          getSelectedMenu={getSelectedMenu}
                                          handleOdd={handleOdd}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="bg-white col-1 p-0">
                                  <div className="row m-0 box-heading">
                                    <div
                                      className="col-12 p-1 text-center"
                                      data-toggle="collapse"
                                      data-target={`#collapse${match.p}`}
                                    >
                                      <i
                                        className="fa fa-chevron-up font-13"
                                        onClick={() => {
                                          removeSingleMatch(match);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="row m-0 text-center">
                                    {match.more ? (
                                      <span
                                        onClick={() => {
                                          setGameSelected(match.mf[0]);
                                          setGameModal(true);
                                        }}
                                        className="special-value"
                                      >
                                        +{match.more}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="text-center py-3">
                                <i className="fa fa-spinner fa-pulse text-danger fa-2x fa-fw" />
                              </div>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </>
                ) : null}
              </div>
            </React.Fragment>
          ))}
      </div>
    </>
  );
};

export default lifecycle(methods)(TableInfo);
