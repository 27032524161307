import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../config';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import '../../styles/Mobile.css';
import moment from 'moment';
import { Modal } from '@material-ui/core';
import ParlayInfo from './ParlayInfo';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  // styling for last ten bets might make a component
  reject: {
    textDecoration: 'line-through',
  },
  strong: {
    color: '#DC252F',
  },
  teams: {
    fontSize: '13px',
    color: '#18334C',
    margin: '0',
  },
  desc: {
    fontSize: '13px',
    fontWeight: 'bolder',
    color: '#323030',
    margin: '0',
  },
  compName: {
    color: '#0E2336',
    fontSize: '11px',
    fontWeight: 'bold',
    margin: '0.05rem 0',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
    margin: ' 0',
  },
  handiCap: {
    color: '#1452CC ',
    fontSize: '13px',
    fontWeight: 'bold',
    margin: ' 0',
    backgroundColor: 'transparent',
    border: 'none',
  },
  money: {
    color: '#1452CC',
    fontSize: '13px',
    fontWeight: 'bold',
    // margin: '0 ',
  },
  amount: {
    fontWeight: 'bold',
    fontSize: '13px',
    color: '#1452CC',
    margin: '0',
  },

  refresh: {
    cursor: 'pointer',
    height: '12px',
    width: '12px',
  },
  spacing: {
    padding: '0.15rem 0.75rem',
    borderBottom: '1px solid rgba(134, 137, 140, 0.7)',
    '& p': {
      margin: '0 ',
    },
  },
  waiting: {
    backgroundColor: '#f9eded',
  },

  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex2: {
    display: 'flex',
    padding: '0.15rem 0.75rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  printWrapper: {
    display: 'flex',
    '& a': {
      fontSize: '13px',
      margin: '0 0.25rem',
    },
  },
  Modal: {
    margin: '0 auto',
    width: '85vw',
    maxWidth: '480px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  division: {
    borderBottom: '1px solid #86898C',
  },
  cardUpper: {
    backgroundColor: '#86898C',
    padding: '0.0rem 0.75rem',
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  card: {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px 5px rgba(0, 0, 0, 0.03)',
    borderRadius: '4px',
    margin: '1rem 0',
    marginTop: '0',
  },
  cardLower: {
    padding: '0.15rem 0.75rem',
    borderRadius: '0 0 4px 4px ',
    '& p': {
      margin: '0 !important',
    },
  },
}));

const BetInfo = ({ bets, total, currentPage, totalPage, User }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [moreInfo, setMoreInfo] = useState(null);
  // modal
  const [open, setOpen] = useState(false);
  const [reference, setReference] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (reference != null) {
      axiosInstance
        .get('portal/bets/' + reference)
        .then((res) => {
          setMoreInfo(res.data);
          setOpen(true);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            localStorage.removeItem('token');
            history.push('/');
          }
        });
    }
  }, [reference, history]);

  return (
    <>
      {bets.map((bet, index) => (
        <div
          className="card card-style ml-0 mr-0 rounded-0"
          style={{
            margin: '0px 15px 0px 15px',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(134, 137, 140, 0.7)',
          }}
          key={index}
        >
          {bet.market === 'PARLAY' ? (
            <>
              <div className={classes.spacing}>
                <p className={classes.desc}>{bet.reference}</p>
                <p className={classes.desc} style={{ fontSize: '11px' }}>
                  {moment(bet.createdAt).format('DD/MM')} {t('Mix Parlay')}
                </p>
                <p className={classes.desc} style={{ fontWeight: 'normal' }}>
                  {bet.type}
                </p>

                <div className={classes.flex}>
                  <div className={classes.printWrapper}>
                    <p className={classes.desc}>
                      @{' '}
                      <CurrencyFormat
                        value={bet.odds}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </p>
                    <button
                      className={`${classes.handiCap} `}
                      onClick={(e) => {
                        e.preventDefault();
                        setReference(bet.reference);
                      }}
                    >
                      {t('More')}
                    </button>
                  </div>
                  <p className={classes.amount}>
                    <CurrencyFormat
                      value={bet.amount}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div
              className={`${classes.spacing} ${
                bet.status === 'PENDING' ? classes.waiting : null
              }`}
            >
              <p className={classes.desc}>{bet.reference}</p>
              <p className={classes.compName}>
                {moment(bet.created).format('DD/MM')} {bet.comp} {bet.compType}
              </p>
              <p className={classes.teams}>
                <span className={bet.high === 1 ? classes.strong : null}>
                  {bet.home}
                </span>{' '}
                {t('vs')}{' '}
                <span className={bet.high === 2 ? classes.strong : null}>
                  {bet.away}
                </span>
              </p>
              {bet.market === 'LIVE' ? (
                <p className={classes.running}>
                  ({t('Running')} {bet.homeGoal}-{bet.awayGoal})
                </p>
              ) : null}
              <p className={classes.desc}>
                {bet.desc}
                {bet.handicap !== '' ? (
                  <span className={classes.handiCap}> ({bet.handicap})</span>
                ) : null}
                {bet.status === 'PENDING' ? (
                  <span className={classes.running}> ({bet.status})</span>
                ) : null}
              </p>
              <div className={classes.flex}>
                <div className={classes.printWrapper}>
                  <p className={classes.desc}>
                    @{' '}
                    <CurrencyFormat
                      value={bet.odds}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </p>
                </div>
                <p className={classes.amount}>
                  <CurrencyFormat
                    value={bet.amount}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
      {total > 0 ? (
        <>
          <div
            className="card card-style ml-0 mr-0 rounded-0"
            style={{
              margin: '0px 15px 0px 15px',
              boxShadow: 'none',
              borderBottom: '1px solid rgba(134, 137, 140, 0.7)',
            }}
          >
            <div className="content">
              <div className={classes.flex}>
                <div className={classes.printWrapper}>
                  <p className={classes.desc}>{t('Total')}</p>
                </div>
                <p className={classes.amount}>
                  <CurrencyFormat
                    value={total}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* Modal */}
      {moreInfo !== null ? (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modalStyle}
        >
          <ParlayInfo moreInfo={moreInfo} User={User} />
        </Modal>
      ) : null}
    </>
  );
};

export default BetInfo;
