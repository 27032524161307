import React, { createContext, useState } from 'react';

export const ParlayContext = createContext();

export const ParlayProvider = (props) => {
  const [parlayBet, setParlayBet] = useState();
  const [isBetParlay, setIsBetParlay] = useState(false);
  const [parlayModal, setParlayModal] = useState(false);

  return (
    <ParlayContext.Provider
      value={{
        parlay: [parlayBet, setParlayBet],
        isParlay: [isBetParlay, setIsBetParlay],
        isParlayModal: [parlayModal, setParlayModal],
      }}
    >
      {props.children}
    </ParlayContext.Provider>
  );
};
