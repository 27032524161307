import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import axiosInstance from '../../config';
import '../../styles/Mobile.css';
import Footer from '../../components/Footer';
import { useHistory } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import BetInfo from '../../components/main/BetInfo';
import { makeStyles } from '@material-ui/core';
import { HistoryContext } from '../../context/HistoryContext';
import LegacyHistory from '../LegacyHistory';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px 5px rgba(0, 0, 0, 0.03)',
    borderRadius: '4px',
  },
  cardHeader: {
    background: '#86898C',
    borderRadius: '4px 4px 0 0',
  },
  spacing: {
    padding: '0.15rem 0.75rem',
    borderBottom: '1px solid rgba(134, 137, 140, 0.7)',
    '& p': {
      margin: '0 ',
    },
  },
  borderDiv: {
    border: '0.5px solid #86898C',
  },
  cardBody: {
    boxShadow: '0px 2px 8px rgba(81, 81, 81, 0.4)',
    borderRadius: '0 0 4px 4px',
  },
  viewBtn: {
    border: '1px solid #86898C',
    color: '#86898C',
    backgroundColor: 'transparent',
  },
}));

const Statement = ({ User }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { historyTab } = useContext(HistoryContext);
  const [lastBet, setLastBet] = useState([]);
  const [total, setTotal] = useState(0);
  const [wager, setWager] = useState([]);
  const [totalWager, setTotalWager] = useState(0);
  const [pageWager, setPageWager] = useState(0);
  const [totalPageWager, setTotalPageWager] = useState(0);
  // history with pagination
  const [hist, setHist] = useState();
  const [totalHistory, setTotalHistory] = useState(0);
  const [pageHistory, setPageHistory] = useState(0);
  const [totalPageHistory, setTotalPageHistory] = useState(0);
  const [activeTab, setActiveTab] = historyTab;
  // legacy history
  const [legacyHistory, setLegacyHistory] = useState();
  const [totalLegacy, setTotalLegacy] = useState(0);
  const [pageLegacy, setPageLegacy] = useState(0);
  const [totalPageLegacy, setTotalPageLegacy] = useState(0);
  const classes = useStyles();

  const loadWager = (pageNo) => {
    axiosInstance
      .get('portal/wager?pageNo=' + pageNo)
      .then((res) => {
        let sum = 0;
        if (pageNo > 0) {
          setWager(wager.concat(res.data.content));
          sum = totalWager;
        } else {
          setWager(res.data.content);
        }
        for (let i = 0; i < res.data.content.length; i++) {
          sum = sum + res.data.content[i].amount;
        }
        setPageWager(res.data.pageable.pageNumber);
        setTotalWager(sum);
        setTotalPageWager(res.data.totalPages);
        // console.log(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push('/');
        }
      });
  };

  //  history
  const loadHistory = (pageNo) => {
    axiosInstance
      .get('portal/summary?pageNo=' + pageNo)
      .then((res) => {
        // console.log(res);
        let sum = 0;
        if (pageNo > 0) {
          setHist(hist.concat(res.data.content));
          sum = totalHistory;
        } else {
          setHist(res.data.content);
        }
        for (let i = 0; i < res.data.content.length; i++) {
          sum = sum + res.data.content[i].amount;
        }
        setPageHistory(res.data.pageable.pageNumber);
        setTotalHistory(sum);
        setTotalPageHistory(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //  legacy history
  const loadLegacyHistory = (pageNo) => {
    axiosInstance
      .get('portal/legacy/history?pageNo=' + pageNo)
      .then((res) => {
        let sum = 0;
        if (pageNo > 0) {
          setLegacyHistory(legacyHistory.concat(res.data.content));
          sum = totalLegacy;
        } else {
          setLegacyHistory(res.data.content);
        }
        for (let i = 0; i < res.data.content.length; i++) {
          sum = sum + res.data.content[i].amount;
        }
        setPageLegacy(res.data.pageable.pageNumber);
        setTotalLegacy(sum);
        setTotalPageLegacy(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (activeTab === 0) {
      axiosInstance
        .get('portal/last')
        .then((res) => {
          setLastBet(res.data);
          let sum = 0;
          for (let i = 0; i < res.data.length; i++) {
            sum = sum + res.data[i].amount;
          }
          // console.log(sum);
          setTotal(sum);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            localStorage.removeItem('token');
            history.push('/');
          }
        });
    } else if (activeTab === 1) {
      loadWager(0);
    } else if (activeTab === 2) {
      loadHistory(0);
    } else if (activeTab === 3) {
      loadLegacyHistory(0);
    }
  }, [history, activeTab]);

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">{t("Statement")}</span>
      </div>
      <div className="page-title-clear" />
      <div className="page-content">
        <div className="second-header header-logo-right" id="sports">
          <ul className="list-unstyled submenulist">
            <li>
              {activeTab === 0 ? (
                <span>{t("Last 10")}</span>
              ) : (
                <button
                  onClick={() => {
                    setActiveTab(0);
                  }}
                >
                  {t("Last 10")}
                </button>
              )}
            </li>
            <li>
              {activeTab === 1 ? (
                <span>{t("Wager")}</span>
              ) : (
                <button
                  onClick={() => {
                    setActiveTab(1);
                  }}
                >
                  {t("Wager")}
                </button>
              )}
            </li>
            <li>
              {activeTab === 2 ? (
                <span>{t("History")}</span>
              ) : (
                <button
                  onClick={() => {
                    setActiveTab(2);
                  }}
                >
                  {t("History")}
                </button>
              )}
            </li>
            {/* <li>
              {activeTab === 3 ? (
                <span>{t("Legacy History")}</span>
              ) : (
                <button
                  onClick={() => {
                    setActiveTab(3);
                  }}
                >
                  {t("Legacy History")}
                </button>
              )}
            </li> */}
          </ul>
        </div>
        {activeTab === 0 ? (
          <>
            <BetInfo bets={lastBet} total={total} User={User} />
            {lastBet.length === 0 ? (
              <p className="text-center my-3">{t("no result found")}</p>
            ) : null}
          </>
        ) : activeTab === 1 ? (
          <>
            <BetInfo bets={wager} total={totalWager} User={User} />
            {pageWager + 1 < totalPageWager ? (
              <>
                <div className="card">
                  <p />
                  <button
                    className="btn btn-m btn-full mb-3 ml-3 mr-3 rounded-xs btn-green"
                    onClick={() => {
                      loadWager(pageWager + 1);
                    }}
                  >
                    {t("Load More ...")}
                  </button>
                  <p />
                </div>
              </>
            ) : null}
            {wager.length === 0 ? (
              <p className="text-center my-3">{t("no result found")}</p>
            ) : null}
          </>
        ) : activeTab === 2 ? (
          <>
            {hist !== undefined ? (
              <>
                {hist.map((htry, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={`${classes.card}`}
                      onClick={() => {
                        let path = '/mobile/me/history/' + htry.date;
                        history.push(path);
                      }}
                    >
                      <div className={`${classes.spacing}`}>
                        <div className="d-flex list-group list-custom-small list-menu ml-0 mr-2">
                          <strong>
                            {htry.date.replace('-', '/').replace('-', '/')}
                          </strong>
                          <span className="badge bg-blue">
                            {htry.ct}&nbsp;&gt;
                          </span>
                        </div>
                        <table className="list-table-detail mt-1">
                          <tbody>
                            <tr>
                              <td className="list-table-detail-title">
                              {t("Amount")}
                              </td>
                              <td
                                // style={{ color: '#000000' }}
                                className="cash-value"
                              >
                                {htry.amount != null ? (
                                  <CurrencyFormat
                                    value={htry.amount}
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    className={`text-primary font-weight-bold`}
                                  />
                                ) : (
                                  <span
                                    className={`text-primary font-weight-bold`}
                                  >
                                    0.00
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="list-table-detail-title">
                              {t("Winning")}
                              </td>
                              <td
                                // style={{ color: '#1553cc' }}
                                className="cash-value"
                              >
                                {htry.winning != null ? (
                                  <CurrencyFormat
                                    value={htry.winning}
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    className={
                                      htry.winning < 0
                                        ? `text-danger font-weight-bold`
                                        : `text-primary font-weight-bold`
                                    }
                                  />
                                ) : (
                                  <span
                                    className={`text-primary font-weight-bold`}
                                  >
                                    0.00
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
                {hist.length === 0 ? (
                  <p className="text-center my-3">{t("no result found")}</p>
                ) : null}
              </>
            ) : null}
            {pageHistory + 1 < totalPageHistory ? (
              <>
                <div className="card">
                  <button
                    className="btn btn-m btn-full mb-3 ml-3 mr-3 rounded-xs btn-green"
                    onClick={() => {
                      loadHistory(pageHistory + 1);
                    }}
                  >
                    {t("Load More ...")}
                  </button>
                  <p />
                </div>
              </>
            ) : null}
          </>
        ) : activeTab === 3 ? (
          <>
            <LegacyHistory
              loadLegacyHistory={loadLegacyHistory}
              setLegacyHistory={setLegacyHistory}
              legacyHistory={legacyHistory}
              pageLegacy={pageLegacy}
              setPageLegacy={setPageLegacy}
            />
          </>
        ) : null}
      </div>
      {/* Page content ends here*/}
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  );
};

export default Statement;
