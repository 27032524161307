import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//import PropTypes from "prop-types";
import axiosInstance from "../../config";
import "../../styles/MobileMain.css";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import Footer from "../../components/Footer";

const News = ({ User }) => {
  let history = useHistory();
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getNews(0);
  }, []);

  const getNews = (page) => {
    // console.log("get News page: " + page);
    axiosInstance
      .get("portal/messages", {
        params: {
          pageNo: page,
          // pageSize: 4,
        },
      })

      .then((res) => {
        setNews(news.concat(res.data.content));
        setPageNo(res.data.number);
        setHasLoadMore(!res.data.last);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push("/");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">
        {t("News")}
        </span>
      </div>

      {/* footer start */}
      <Footer />
      {/* footer end */}

      <div className="page-title-clear" />
      <div className="page-content">
        {loading ? (
          <LoadingPage />
        ) : (
          <>
            {news.length > 0 ? (
              <InfiniteScroll
                pageStart={-1}
                loadMore={() => {
                  if (hasLoadMore) {
                    // console.log("load more " + (pageNo + 1));
                    getNews(pageNo + 1);
                  }
                }}
                threshold={10}
                hasMore={hasLoadMore}
                loader={
                  <div key={-1} className="text-center mb-3">
                    <div className="spinner-border color-red-dark" role="status">
                      <span className="sr-only">{t("Loading")}...</span>
                    </div>
                  </div>
                }
              >
                {news.map((data, index) => (
                  <>
                    <div
                      key={index}
                      className="card card-style mb-3 mt-3 rounded"
                    >
                      <div className="accordion" id={"accordion-" + index}>
                        <button
                          className="news-title btn accordion-btn border-0"
                          data-toggle="collapse"
                          data-target={"#collapse" + index}
                        >
                          {/* <span className="news-title">17/11/2020 12:44 pm</span> */}
                          <span className="news-title">
                            {Moment(data.startDate).format(
                              "ddd, DD MMM - hh:mm a"
                            )}
                          </span>
                          <i className="fa fa-chevron-down font-10 accordion-icon"></i>
                        </button>

                        <div
                          id={"collapse" + index}
                          className="collapse bg-theme ml-3 mr-3 show"
                          data-parent={"#accordion-" + index}
                        >
                          <div className="pb-3 news-content">
                            {data.content}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </InfiniteScroll>
            ) : (
              <EmptyPage icon={"fa-newspaper"} title={t("No news")} />
            )}
          </>
        )}
      </div>
      {/* Page content ends here*/}
    </>
  );
};

export default News;
