import React from 'react';
import { useHistory } from 'react-router-dom';
const Header = ({ title }) => {
  const history = useHistory();
  return (
    <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
      <span className="header-title color-white">
        {title}
      </span>

      <span
        className="header-icon header-icon-1"
        onClick={() => {
          history.goBack();
        }}
      >
        <i className="fas color-white fa-arrow-left color-red-light"></i>
      </span>
    </div>
  );
};

export default Header;
