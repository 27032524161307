import React, {useState, useEffect} from 'react';

const MoreGame = ({
  odd,
  idxPrice,
  prefixHdp,
  idxHdp,
  handleOdd,
  parlayBet,
}) => {
  
  const [constArr] = useState(['col1', 'col2', 'col3']);

  useEffect(() => {
    if (parlayBet && parlayBet.parlays) {
      for (let i = 0; i < parlayBet.parlays.length; i++) {
        let element = document.getElementById(parlayBet.parlays[i].odd + parlayBet.parlays[i].pick + 'm') ;
        if (element) {
          element.className = 'box pb-1 w-100 odd-box bg-white odd-box-selected';
        }
      }
    }
  },[parlayBet]);
  
  const generateOdd = () => {
    return odd.lbl.map(function(each, idx){
      return generateRow(idx);
    });
  }
  
  const generateRow = (row) => {
    return (
      <div className="row align-items-center mb-0 pr-2">
        {generateCol5(row)} {/* generate label */}
        {generateCol7(row)} {/* generate odds/price */}
      </div>
    );
  }
  
  const generateCol5 = (row) => {
    return (
      <div className="col-5" key={'col5'+row}>
        {odd.lbl[row]}
      </div>
    );
  }

  const generateCol7 = (row) => {
    return (
      <>
        <div className="col-7" key={'col7'+row}>
          <div className="row mb-0">
            {generateDetail(row)}
          </div>
        </div>
      </>
    );
  }

  const generateDetail = (row) => {
    return constArr.map(function(each, col){
      return (
        <>
          <div className="col p-1" key={row+'detail'+col}>
            { odd[each]? (
            <button
              className={`box pb-1 w-100 odd-box bg-white`}
              id={odd[each][0] + odd[each][1 + row * 2] + 'm'}
              onClick={(e) => {
                let match = [];
                match['p'] = odd[each][0];
                handleOdd(match, odd[each][1 + row * 2],'m');
              }}
            >
              <span className="small font-weight-bold">
                {generateUpperOdd(row,each,prefixHdp,idxHdp, odd.gameType)}
              </span>{' '}
              <span
                // className={`${
                //   (match.o1 &&
                //     match.old &&
                //     match.old.o1 &&
                //     match.o1[htIndex+1] !==
                //       match.old.o1[htIndex+1] &&
                //     'bg-yellow') ||
                //   ''
                // }`}
              >
                {odd[each][idxPrice + row * 2]}
              </span>
            </button>
            ) : null }
          </div>
        </>
      )
    });
  }

  const generateUpperOdd = (row, colArr, prefix, idx, gameType) => {
    let upperOdd = prefix == null || prefix.length === 0 || prefix.length < row + 1 ? '' : prefix[row]; // prefix such as o, u, odd, even
    
    if (row !== 1 || (gameType !== 'AHFT' && gameType !== 'AHFH')) {
      upperOdd += (odd[colArr] == null || idx == null || odd[colArr].length < idx + 1? '' :odd[colArr][idx]);
    }
    else if (row === 1 && (gameType === 'AHFT' || gameType === 'AHFH')) { // need to reverse handicap for AWAY (identifiable by gameType - AHFT and AHFH)
      if (idx != null && odd[colArr].length  >= idx + 1 && odd[colArr][idx] !== '') 
      {
        if (odd[colArr][idx].indexOf('-') !== -1) upperOdd += odd[colArr][idx].replace('-',''); // reverse negative become positive
        else if (odd[colArr][idx] === '0.0') upperOdd += odd[colArr][idx]; // keep 0.0 as it is - do not add negative sign in front
        else upperOdd += '-'+odd[colArr][idx]; // reverse positive become negative
      }
    }
    
    if (colArr === 'col2' && (gameType === 'OE' || gameType === 'OEFH' || gameType === '1X2' || gameType === '1X2FH')) {
      upperOdd = 'HT '+upperOdd;
    }
    
    return upperOdd;
  }

  return generateOdd();

}

export default MoreGame;