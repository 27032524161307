import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import axiosInstance from '../../config';
import { useHistory } from 'react-router-dom';

const Withdraw = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const [pin, setPin] = useState(null);
  const handlePin = () => {
    axiosInstance
      .post('portal/otp')
      .then((res) => {
        setPin(res.data.otp);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
      });
  };
  useEffect(() => {
    handlePin();
  }, [history]);
  return (
    <>
      <Header title="Withdraw" />
      <Footer />
      <div className="page-title-clear" />
      <div className="card bg-white px-5 pb-4 text-center">
        <p/>
        <p/>
        {pin !== null ? (
          <>
            <p className="text-dark text-center my-0">{t("Your Withdrawal PIN is")}:</p>
            <p/>
            <h3>{pin}</h3>
            <p/>
            <p className="text-body font-weight-bold my-3">
            {t("Please cash out immediately as your PIN is effective for 10 minutes only")}.
            </p>
          </>
        ) : null}
        <p className="text-dark text-center my-3">
        {t("Click the Generate PIN button to get a new Withdrawal PIN")}.
        </p>
        <p className="text-dark text-center my-3">{t("Your PIN will expire in 10 minutes")}</p>
        
        <button className="btn btn-success btn-sm my-3" onClick={handlePin}>
        {t("Generate PIN")}
        </button>
      </div>
    </>
  );
};

export default Withdraw;
