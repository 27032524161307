import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../config";
import "../../styles/MobileMain.css";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import CurrencyFormat from "react-currency-format";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: "#fff",
    boxShadow: "0px 10px 20px 5px rgba(0, 0, 0, 0.03)",
    borderRadius: "4px"
  },
  spacing: {
    padding: "0.15rem 0.75rem",
    borderBottom: "1px solid rgba(134, 137, 140, 0.7)",
    "& p": {
      margin: "0 "
    }
  }
}));

const Cash = ({ User }) => {
  const classes = useStyles();
  let history = useHistory();
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(
    () => {
      setLoading(true);
      axiosInstance
        .get("portal/txn")
        .then(res => {
          setTransaction(res.data.content);
          // console.log(res.data);
        })
        .catch(err => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            localStorage.removeItem('token');
            history.push("/");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [history]
  );
  return (
    <>
      <Header title="Cash" />
      <div className="page-title-clear" />
      <div className="page-content">
        {loading ? (
          <LoadingPage />
        ) : (
          <>
            {transaction.length > 0 ? (
              <>
                {transaction.map((tx, index) => (
                  <>
                    <div
                      className={`${classes.card}`}
                      onClick={() => {
                        let path = "/mobile/me/cash/" + tx.date;
                        history.push(path);
                      }}
                    >
                      <div className={`${classes.spacing}`}>
                        <div className="d-flex list-group list-custom-small list-menu ml-0 mr-2">
                          <strong>
                            {tx.date.replace("-", "/").replace("-", "/")}
                          </strong>
                          <span className="badge bg-blue">
                            &nbsp;{tx.ct}&nbsp;&gt;
                          </span>
                        </div>
                        <table className="list-table-detail mt-1">
                          <tr>
                            <td className="list-table-detail-title">
                            {t("Beginning / End")}
                            </td>
                            <td
                              style={{ color: "#000000" }}
                              className="cash-value"
                            >
                              {tx.beginning != null ? (
                                <CurrencyFormat
                                  value={tx.beginning}
                                  decimalScale={2}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              ) : (
                                0.0
                              )}
                            </td>
                            <td>&nbsp;|&nbsp;</td>
                            <td
                              style={{ color: "#000000" }}
                              className="cash-value"
                            >
                              {tx.end != null ? (
                                <CurrencyFormat
                                  value={tx.end}
                                  decimalScale={2}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              ) : (
                                0.0
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="list-table-detail-title">
                            {t("Credit / Debit")}
                            </td>
                            <td
                              style={{ color: "#1553cc" }}
                              className="cash-value"
                            >
                              {tx.credit != null ? (
                                <CurrencyFormat
                                  value={tx.credit}
                                  decimalScale={2}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              ) : (
                                0.0
                              )}
                            </td>
                            <td>&nbsp;|&nbsp;</td>
                            <td
                              style={{ color: "#dc262f" }}
                              className="cash-value"
                            >
                              {tx.debit != null ? (
                                <CurrencyFormat
                                  value={tx.debit * -1}
                                  decimalScale={2}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              ) : (
                                0.0
                              )}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <EmptyPage icon={"fa-cash-stack"} title={t("No Cash")} />
            )}
          </>
        )}
      </div>
      {/* Page content ends here*/}
    </>
  );
};

export default Cash;
