import React, { createContext, useState } from 'react';

export const HistoryContext = createContext();

export const HistoryProvider = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <HistoryContext.Provider
      value={{
        historyTab: [activeTab, setActiveTab],
      }}
    >
      {props.children}
    </HistoryContext.Provider>
  );
};
