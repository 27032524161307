import React from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbars from 'react-custom-scrollbars';
import { makeStyles } from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles((theme) => ({
  oddsWrapperColored: {
    background: '#F5F5F5',
  },
  oddsWrapper: {
    background: '#FFF',
  },
}));

const SuccessParlay = ({ parlaySuccess, setBetSuccessModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div className="card">
        <div className="card-header bg-success border-none text-white font-weight-bold">
          {t('Bet Placed successfully')}
        </div>
        <div className="card-body">
          <Scrollbars
            autoHeight
            autoHeightMin={70}
            autoHeightMax={'70vh'}
            className={`border-bottom`}
          >
            {parlaySuccess.parlays.map((parlayMatch, index) => (
              <div
                className={
                  index % 2 === 0
                    ? `${classes.oddsWrapper} my-1`
                    : `${classes.oddsWrapperColored} my-1`
                }
                key={index}
              >
                <p className={`text-body font-weight-bold mb-0`}>
                  {parlayMatch.comp}
                </p>
                <p className={`text-body mb-0`}>
                  {parlayMatch.home} {t('vs')} {parlayMatch.away}
                </p>
                {parlayMatch.market === 'LIVE' ? (
                  <p className={`text-danger mb-0`}>
                    ({t('Running')} {parlayMatch.homeGoal}-
                    {parlayMatch.awayGoal})
                  </p>
                ) : null}

                <p className={`text-body font-weight-bold mb-0`}>
                  {parlayMatch.desc}{' '}
                  {parlayMatch.handicap !== '' ? (
                    <span className="text-primary">
                      ({parlayMatch.handicap})
                    </span>
                  ) : null}
                </p>

                {parlayMatch.status === 'PENDING' ? (
                  <p className={`text-danger mb-0`}>({parlayMatch.status})</p>
                ) : null}
                <p className={`text-body font-weight-bold mb-0`}>
                  @{' '}
                  <CurrencyFormat
                    value={parlayMatch.odds}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </p>
              </div>
            ))}
          </Scrollbars>
          <div
            className={`d-flex justify-content-between align-items-center my-1`}
          >
            <p className={`text-body mb-0`}>{t('Parlay')}</p>
            <p className={`text-body mb-0 font-weight-bold`}>
              {parlaySuccess.type} @ {parlaySuccess.odds.toFixed(2)}
            </p>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center my-1`}
          >
            <p className={`text-body mb-0`}>{t('Amount')}</p>
            <p className={`text-body mb-0 font-weight-bold`}>
              {parlaySuccess.amount}
            </p>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center my-1`}
          >
            <p className={`text-body mb-0`}>{t('Payout')}</p>
            <p className={`text-body mb-0 font-weight-bold`}>
              {(
                (parlaySuccess.amount * parlaySuccess.odds) /
                parseInt(parlaySuccess.type.split(' ')[2])
              ).toFixed(2)}
            </p>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center my-1`}
          >
            <p className={`text-body mb-0`}>{t('Account')}</p>
            <p className={`text-body mb-0 font-weight-bold`}>
              {parlaySuccess.account}
            </p>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center my-1`}
          >
            <p className={`text-body mb-0`}>{t('Bet no.')}</p>
            <p className={`text-body mb-0 font-weight-bold`}>
              {parlaySuccess.reference}
            </p>
          </div>

          {/* buttons */}
          <div className={`d-flex justify-content-center align-items-center`}>
            <button
              className="btn  btn-success btn-sm "
              onClick={() => setBetSuccessModal(false)}
            >
              {t('Done')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessParlay;
