import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//import PropTypes from "prop-types";
import axiosInstance from "../../config";
import "../../styles/MobileMain.css";
import Footer from "../../components/Footer";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";

const Result = ({ User }) => {
  let history = useHistory();
  const { t } = useTranslation();

  const [dateFilter, setDateFilter] = useState("1");
  const [dateFilterValue, setDateFilterValue] = useState(
    Moment(new Date().toDateString()).format("yyyy-MM-DD")
  );

  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);

  const [sportTypeModels, setSportTypeModels] = useState([]);
  const [sportTypeSelected, setSportTypeSelected] = useState("");

  useEffect(() => {
    getSportType();
  }, []);

  const toggleDrawer = (show) => {
    if (!show) {
      window.$("#menu-result-filter").hideMenu();
    } else {
      window.$("#menu-result-filter").showMenu();
    }
  };

  const getMinDate = () => {
    let date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  };

  const getSportType = () => {
    setLoading(true);
    axiosInstance
      .get("public/selectSport")
      .then((res) => {
        setSportTypeModels(res.data);
        setSportTypeSelected(res.data[0].key);
        getResult(res.data[0].key);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push("/");
        }
      });
  };

  const getResult = (sportType) => {
    let tempDate = document.querySelector("#customDate").value;
    let date = Moment(tempDate.toString()).format("DD-MM-yyyy");

    if (sportType === undefined) {
      sportType = sportTypeSelected;
    }

    setLoading(true);
    axiosInstance
      .get("public/results", {
        params: {
          reportDate: date,
          searchSport: sportType,
        },
      })
      .then((res) => {
        let datas = [];
        let modelTemp = {};
        let resultTemp = [];
        res.data.results.map((data, index) => {
          if (data.competitionName !== undefined) {
            if (index !== 0) {
              modelTemp.result = resultTemp;
              datas = datas.concat(modelTemp);

              resultTemp = [];
              modelTemp = {};
            }
            modelTemp.competitionName = data.competitionName;
            // resultTemp = resultTemp.concat({
            //   time: data.matchTime,
            //   name: data.homeName + " vs " + data.awayName,
            //   fh: data.fh !== undefined ? data.fh[0] + " - " + data.fh[1] : "",
            //   ft: data.ft !== undefined ? data.ft[0] + " - " + data.ft[1] : "",
            // });
            // if (data.more !== undefined) {
            //   data.more.map((more, index) => {
            //     resultTemp = resultTemp.concat({
            //       time: undefined,
            //       name: more.hdpType,
            //       fh:
            //         more.fh !== undefined
            //           ? more.fh[0] + " - " + more.fh[1]
            //           : "",
            //       ft:
            //         more.ft !== undefined
            //           ? more.ft[0] + " - " + more.ft[1]
            //           : "",
            //     });
            //   });
            // }
          }
          resultTemp = resultTemp.concat({
            time: data.matchTime,
            name: data.homeName + " vs " + data.awayName,
            fh: data.fh !== undefined ? data.fh[0] + ":" + data.fh[1] : "-:-",
            ft: data.ft !== undefined ? data.ft[0] + ":" + data.ft[1] : "-:-",
          });
          if (data.more !== undefined) {
            data.more.map((more, index) => {
              resultTemp = resultTemp.concat({
                time: undefined,
                name: more.hdpType,
                fh:
                  more.fh !== undefined ? more.fh[0] + ":" + more.fh[1] : "-:-",
                ft:
                  more.ft !== undefined ? more.ft[0] + ":" + more.ft[1] : "-:-",
              });
              return more;
            });
          }
          return data;
        });
        setModels(datas);
        // console.log(datas);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push("/");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">{t("Results")}</span>

        <div
          className="header-icon header-icon-4"
          onClick={() => {
            toggleDrawer(true);
          }}
        >
          <button className="btn btn-link color-white">
            <i className="fas color-white fa-filter" />
          </button>
        </div>
      </div>

      {/* footer start */}
      <Footer />
      {/* footer end */}

      {/* Page content start here*/}
      <div className="page-title-clear" />
      <div className="page-content">
        {loading ? (
          <LoadingPage />
        ) : (
          <>
            {models.length > 0 ? (
              <>
                {models.map((data, indexTable) => (
                  <div
                    key={"table-" + indexTable}
                    className="card card-style mb-3 mt-3 rounded"
                  >
                    <span className="table-header">{data.competitionName}</span>
                    <table className="table mb-0 text-center table-rounded-bottom">
                      <thead>
                        <tr className="mb-0 box-heading">
                          <th scope="col" />
                          <th
                            scope="col"
                            className="p-1 width-18p odd-table-heading"
                          >
                            {t("HT")}
                          </th>
                          <th
                            scope="col"
                            className="p-1 width-18p odd-table-heading"
                          >
                            {t("FT")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.result.map((result, index) => (
                          <tr key={"data-" + index}>
                            <th scope="row">
                              <div className="table-content-col-1">
                                {result.time ? (
                                  <span className="result-table-content-date">
                                    {Moment(result.time).format(
                                      "DD MMM - hh:mm a"
                                    )}
                                  </span>
                                ) : null}
                                <div className="table-result-team-name mt-1">
                                  {result.name}
                                </div>
                              </div>
                            </th>
                            <td>{result.fh}</td>
                            <td>{result.ft}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </>
            ) : (
              <EmptyPage icon={"fa-chart-bar"} title={t("No Result")} />
            )}
          </>
        )}
      </div>
      {/* Page content ends here*/}

      {/* drawer menu */}
      <div
        id="menu-result-filter"
        className="menu menu-box-right rounded-0 bg-white"
        data-menu-width={"300px"}
        data-menu-active="nav-components"
        style={{ display: "block", width: "300px" }}
      >
        <div
          className="header header-logo-center mb-4 result-drawer"
          onClick={() => {
            toggleDrawer(false);
          }}
        >
          <p className="header-title align-right">{t("Filter")}</p>
          <div className="header-icon header-icon-4">
            <i className="fas fa-chevron-right" />
          </div>
        </div>

        <div className="p-3">
          <div className="input-style input-style-2 input-required">
            <span>{t("Sport Type")}</span>
            <em>
              <i className="fa fa-angle-down" />
            </em>
            <select
              className="form-control"
              value={sportTypeSelected}
              onChange={(e) => {
                setSportTypeSelected(e.target.value);
              }}
            >
              {sportTypeModels.map((data, index) => (
                <option value={data.key} key={data.key}>
                  {data.val}
                </option>
              ))}
            </select>
          </div>

          <div className="input-style input-style-2 input-required mt-4">
            <span>{t("Day")}</span>
            <em>
              <i className="fa fa-angle-down" />
            </em>
            <select
              className="form-control"
              value={dateFilter}
              onChange={(e) => {
                setDateFilter(e.target.value);
                if (e.target.value === "1") {
                  setDateFilterValue(
                    Moment(new Date().toDateString()).format("yyyy-MM-DD")
                  );
                } else if (e.target.value === "2") {
                  let tempDate = new Date();
                  tempDate.setDate(tempDate.getDate() - 1);

                  setDateFilterValue(
                    Moment(tempDate.toDateString()).format("yyyy-MM-DD")
                  );
                }
              }}
            >
              <option value="1">{t("Today")}</option>
              <option value="2">{t("Yesterday")}</option>
              <option value="custom" disabled>
              {t("Custom")}
              </option>
            </select>
          </div>

          <div className="input-style input-style-2 input-required mt-4">
            <span className="color-highlight input-style-1-active input-style-1-inactive">
            {t("Pick a date")}
            </span>
            <em>
              <i className="fa fa-angle-down" />
            </em>
            <input
              id="customDate"
              type="date"
              value={dateFilterValue}
              min={Moment(getMinDate()).format("yyyy-MM-DD")}
              onChange={(e) => {
                setDateFilterValue(e.target.value);
                setDateFilter("custom");
              }}
            />
          </div>

          <span
            className="btn btn-m btn-full rounded-xs text-uppercase font-900 shadow-s btn-green mt-4"
            onClick={() => {
              toggleDrawer(false);
              getResult();
            }}
          >
            {t("Submit")}
          </span>
        </div>
      </div>
      {/* end drawer menu */}
    </>
  );
};

export default Result;
