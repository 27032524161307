import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
//import PropTypes from 'prop-types';
import axiosInstance from "../config";
import Footer from "../components/Footer";
import TableInfo from "../components/TableInfo";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import "../styles/Mobile.css";
import "../styles/Navbar.css";
import { ParlayProvider, ParlayContext } from "../context/ParlayContext";
import LeagueFilter from "../components/LeagueFilter";

const useStyles = makeStyles(theme => ({
  groupButton: {
    border: "1px solid #86898C",
    boxSizing: "border-box",
    borderRadius: "8px"
  },
  inactiveButton: {
    color: "#323030",
    fontSize: "14px"
  },
  activeButton: {
    background: "#ff0000",
    color: "#fff"
  },
  Modal: {
    right: 0,
    position: "absolute",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    height: "100%",
    overflowY: "auto"
  },
  // game modal
  leagueFilterModal: {
    height: "100%",
    display: "block",
    margin: "auto",
    overflowY: "scroll"
  }
}));

const Mobile = ({ User }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  // context of parlay
  const { parlay, isParlay, isParlayModal } = useContext(ParlayContext);
  const [parlayBet, setParlayBet] = parlay;
  const [isBetParlay, setIsBetParlay] = isParlay;
  const [parlayModal, setParlayModal] = isParlayModal;
  

  // switching between parlay and single
  const betTypeSingle = () => {
    setParlayBet(undefined);
    setIsBetParlay(false);
  };
  const betTypeParlay = () => {
    setIsBetParlay(true);
  };

  const toggleDrawer = show => {
    if (!show) {
      window.$("#odds-filter").hideMenu();
    } else {
      window.$("#odds-filter").showMenu();
    }
  };

  const [expand, setExpand] = useState("");
  const [matches, setMatches] = useState([]);
  // const [line, setLine] = useState('Double');
  const [sortOrder, setSortOrder] = useState("");
  const [onlyFav, setOnlyFav] = useState(false);
  //const [tmatches, setTMatches] = useState([]);
  const [seconds, setSeconds] = useState(0);
  const [refresh, setRefresh] = useState(true);
  //const [todaySeconds, setTodaySeconds] = useState(0);
  const [openLeagueFilter, setOpenLeagueFilter] = useState(false);

  // const handleLineChange = (event) => {
  //   setLine(event.target.value);
  // };

  /*const handleOrderChange = (event) => {
    setSortOrder(event.target.value);
    setSeconds(0);
  };*/

  /*
  const handleTypeChange = (event) => {
    let url = 'restricted/oddType/';
    axiosInstance
      .patch(url + event.target.value)
      .then(() => {
        setSeconds(0);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push('/');
        }
      });
  }; */

  const getSelectedMenu = group => {
    var menuStorage = localStorage.getItem("menu");
    if (menuStorage) {
      var res = JSON.parse(menuStorage)[group];
      return res;
    } else {
      return "";
    }
  };

  const getMenu = () => {
    var menuStorage = localStorage.getItem("menu");
    if (!menuStorage) {
      var defaultMenu = {
        Game: "Football",
        Parlay: "Single",
        Odds: "Live"
      };
      localStorage.setItem("menu", JSON.stringify(defaultMenu));
      return defaultMenu;
    }
    return JSON.parse(menuStorage);
  };

  const setMenu = (group, menu, link) => {
    setNavMenu(m => {
      m[group] = menu;
      return m;
    });
    setMatches([]);
    var menuStorage = JSON.parse(localStorage.getItem("menu"));
    menuStorage[group] = menu;
    localStorage.setItem("menu", JSON.stringify(menuStorage));

    if (group === "Parlay" && menu === "Single") {
      setIsBetParlay(false);
    } else if (group === "Parlay" && menu === "Parlay") {
      setIsBetParlay(true);
    }
    setRefresh(true);
    setExpand("");
    clearOddSelection();
    setSeconds(0);
  };

  // for parlay and single need not to reset state it will reset state which we dont want to happen
  const setMenu2 = (group, menu) => {
    setNavMenu(m => {
      m[group] = menu;
      return m;
    });
    var menuStorage = JSON.parse(localStorage.getItem("menu"));
    menuStorage[group] = menu;
    localStorage.setItem("menu", JSON.stringify(menuStorage));

    if (group === "Parlay" && menu === "Single") {
      betTypeSingle();
    } else if (group === "Parlay" && menu === "Parlay") {
      betTypeParlay();
    }
    setRefresh(true);
    setExpand("");
    clearOddSelection();
    setSeconds(0);
  };

  const clearOddSelection = oddPick => {
    var eleList = [];
    if (oddPick) {
      eleList = document.querySelectorAll('button[id*="' + oddPick + '"]');
    } else {
      eleList = document.querySelectorAll(".odd-box-selected");
    }
    // console.log('eleList length = '+eleList.length);
    if (eleList) {
      for (let k = 0; k < eleList.length; k++) {
        var ele = eleList[k];
        ele.className = "box pb-1 w-100 odd-box bg-white";
      }
    }
  };

  const [navMenu, setNavMenu] = useState(getMenu());

  /*
  const getGameImage = () => {
    if (getSelectedMenu('Game') === 'Basketball') {
      return 'basketball-2.svg';
    }
    return 'soccer-1.svg';
  }; */

  const assignOldMatch = newMatches => {
    return newMatches.map(newMatch => {
      let oldLeague = matches.find(m => {
        return (
          newMatch.lf &&
          newMatch.lf[0] &&
          m.lf &&
          m.lf[0] &&
          m.lf[0] === newMatch.lf[0]
        );
      });
      if (oldLeague) {
        newMatch.matches.map(nmm => {
          let oldMatch = oldLeague.matches.find(m => {
            return m.p === nmm.p;
          });
          if (oldMatch) {
            nmm["old"] = oldMatch;
            if (oldMatch.sel) {
              nmm.sel = oldMatch.sel;
            }
            if (nmm.o1 && (nmm.o1[5] === "" || nmm.o1[7] === "")) {
              nmm.sel = "FT";
            }
          }
        });
      }
      return newMatch;
    });
  };

  const loadSingleMatch = match => {
    setExpand(expand === "" ? match.p : expand + "," + match.p);
    let url = "restricted/mobile/oddHdp/" + match.p;
    axiosInstance
      .get(url)
      .then(res => {
        match.o1 = res.data.o1;
        match.o2 = res.data.o2;
        if (res.data.o3) match.o3 = res.data.o3;
      })
      .then(() => {
        setMatches(matches);
        //setUpdateComponent(new Date());
        //window.init_template();
      })
      .catch(err => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push("/");
        }
      });
  };

  useEffect(
    () => {
      if (
        document.querySelector("#oddType") != null &&
        document.querySelector("#typeVal").value === ""
      ) {
        document.querySelector("#oddType").value = User && User.user.oddType;
        document.querySelector("#typeVal").value = "1";
      }
      let refreshSecond = 15;

      let callOddHdp = () => {
        let selected = getSelected();
        let status = "R";
        if (getSelectedMenu("Odds") === "Early") {
          status = "E";
          refreshSecond = 30;
        } else if (getSelectedMenu("Odds") === "Today") {
          status = "T";
          refreshSecond = 30;
        }

        let url = "restricted/mobile/odds?status=" + status;

        if (getSelectedMenu("Parlay") === "Parlay") {
          url += "&parlay=Y";
        }
        if (onlyFav) {
          url += "&fav=1";
        }
        if (sortOrder === "Time") {
          url += "&ord=M";
        }
        if (getSelectedMenu("Game") === "Basketball") {
          url += "&s=2";
        }
        if (selected !== "") {
          url += "&selected=" + selected;
        }

        return axiosInstance
          .post(url)
          .then(res => {
            let sel = "";
            var withOldMatch = assignOldMatch(res.data);
            // setMatches([]);
            setMatches(withOldMatch);
            for (let i = 0; i < withOldMatch.length; i++) {
              let m = withOldMatch[i];
              for (let j = 0; j < m.matches.length; j++) {
                if (m.matches[j].o1) {
                  sel += m.matches[j].p + ",";
                }
              }
            }
            if (expand === "" && sel !== "") setExpand(sel.slice(0, -1));
          })
          .then(() => {
            setSeconds(refreshSecond);
          })
          .catch(err => {
            setSeconds(refreshSecond);
            console.log(err);
            if (err.response && err.response.data.status === 401) {
              localStorage.removeItem('token');
              history.push("/");
            }
          });
      };
      if (!seconds) callOddHdp();
      const timer = setInterval(() => setSeconds(seconds - 1), 1000);
      return () => clearInterval(timer);
    },
    [User, isBetParlay, sortOrder, seconds, onlyFav, history]
  );

  const getSelected = league => {
    if (refresh) {
      setRefresh(false);
      return "";
    }
    // console.log('expand = '+expand);
    return expand;
  };

  const refreshLeague = league => {
    let selected = getSelected(league);
    let status = "R";
    if (getSelectedMenu("Odds") === "Early") {
      status = "E";
    } else if (getSelectedMenu("Odds") === "Today") {
      status = "T";
    }
    let url = "restricted/mobile/odds?status=" + status;
    if (getSelectedMenu("Parlay") === "Parlay") {
      url += "&parlay=Y";
    }
    if (onlyFav) {
      url += "&fav=1";
    }
    if (sortOrder === "Time") {
      url += "&ord=M";
    }
    if (getSelectedMenu("Game") === "Basketball") {
      url += "&s=2";
    }
    url += "&selected=" + selected;

    axiosInstance
      .post(url)
      .then(res => {
        var withOldMatch = assignOldMatch(res.data);
        setMatches(withOldMatch);
      })
      .catch(err => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push("/");
        }
      });
  };

  const expandCollapseLeague = league => {
    let isLeagueMatchExpanded = false;
    league.matches.forEach(match => {
      if (match.o1 || match.o2 || match.o3) {
        isLeagueMatchExpanded = true;
      }
    });
    if (isLeagueMatchExpanded) {
      removeLeagueMatch(league);
    } else {
      expandLeagueMatch(league);
    }
  };

  const expandLeagueMatch = league => {
    let e = expand.split(",");
    league.matches.forEach(match => {
      e.push(match.p);
    });
    e = e.join(",");
    setExpand(e);
    let status = "R";
    if (getSelectedMenu("Odds") === "Early") {
      status = "E";
    } else if (getSelectedMenu("Odds") === "Today") {
      status = "T";
    }
    let url = "restricted/mobile/odds?status=" + status;
    if (getSelectedMenu("Parlay") === "Parlay") {
      url += "&parlay=Y";
    }
    if (onlyFav) {
      url += "&fav=1";
    }
    if (sortOrder === "Time") {
      url += "&ord=M";
    }
    if (getSelectedMenu("Game") === "Basketball") {
      url += "&s=2";
    }
    url += "&selected=" + e;

    axiosInstance
      .post(url)
      .then(res => {
        var withOldMatch = assignOldMatch(res.data);
        setMatches(withOldMatch);
      })
      .catch(err => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push("/");
        }
      });
  };

  const removeLeagueMatch = league => {
    let removeMatch = [];
    league.matches.forEach(match => {
      removeMatch.push(match.p);
      match.o1 = undefined;
      match.o2 = undefined;
      match.o3 = undefined;
    });

    setExpand(
      expand
        .split(",")
        .filter(p => removeMatch.indexOf(p) === -1)
        .join(",")
    );
  };

  const removeSingleMatch = match => {
    setExpand(
      expand
        .split(",")
        .filter(p => p !== match.p)
        .join(",")
    );
    match.o1 = undefined;
    match.o2 = undefined;
    match.o3 = undefined;
  };

  // handle bet type
  // const handleBetType = (e) => {
  //   if (e.target.value === 'single') {
  //     setIsBetParlay(false);
  //   } else {
  //     betTypeParlay();
  //   }
  // };

  return (
    <>
      <ParlayProvider>
        <div className="header header-fixed mb-4 bg-dardred-light shadow-l">
          <div className="row align-items-center mb-0 mx-2 py-1">
            <div className="col px-1">
              <ul className="list-unstyled topmenu mb-0 d-flex align-items-center pt-2">
                <li>
                  <button
                    type="button"
                    id="header-1"
                    data-toggle="dropdown"
                    className="btn p-0 btn-danger text-light fs-14"
                  >
                    {navMenu && t(navMenu["Game"])}{" "}
                    <i className="fas fa-angle-down" />
                  </button>
                  <div
                    className="dropdown-menu bg-theme border-0 shadow-l rounded-0 mr-2 p-0 mt-2"
                    aria-labelledby="header-1"
                  >
                    <div className="list-group list-custom-small">
                      <button
                        className="dropdown btn btn-link px-2 py-2"
                        onClick={e => {
                          setMenu("Game", "Football", "#Football");
                        }}
                      >
                        {t("Football")}
                      </button>
                      <button
                        className="dropdown btn btn-link px-2 py-2"
                        onClick={e => {
                          setMenu("Game", "Basketball", "#Basketball");
                        }}
                      >
                        {t("Basketball")}
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <button
                    type="button"
                    id="header-2"
                    data-toggle="dropdown"
                    className="btn p-0 btn-danger text-light fs-14"
                  >
                    {navMenu && t(navMenu["Odds"])}{" "}
                    <i className="fas fa-angle-down" />
                  </button>
                  <div
                    className="dropdown-menu bg-theme border-0 shadow-l rounded-0 mr-2 p-0 mt-2"
                    aria-labelledby="header-2"
                  >
                    <div className="list-group list-custom-small">
                      <button
                        className="dropdown btn btn-link px-2 py-2"
                        onClick={e => {
                          setMenu("Odds", "Today", "#Today");
                        }}
                      >
                        {t("Today")}
                      </button>
                      <button
                        className="dropdown btn btn-link px-2 py-2"
                        onClick={e => {
                          setMenu("Odds", "Early", "#Early");
                        }}
                      >
                        {t("Early")}
                      </button>
                      <button
                        className="dropdown btn btn-link px-2 py-2"
                        onClick={e => {
                          setMenu("Odds", "Live", "#Live");
                        }}
                      >
                        {t("Live")}
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  {/* <select
                    name=""
                    id=""
                    className={`border-none ${classes.dropdown} text-white `}
                    onChange={handleBetType}
                  >
                    <option value="single">Single</option>
                    <option value="parlay">Parlay</option>
                  </select> */}
                  <button
                    type="button"
                    id="header-3"
                    data-toggle="dropdown"
                    className="btn p-0 btn-danger text-light fs-14"
                  >
                    {navMenu && t(navMenu["Parlay"])}{" "}
                    <i className="fas fa-angle-down" />
                  </button>
                  <div
                    className="dropdown-menu bg-theme border-0 shadow-l rounded-0 mr-2 p-0 mt-2"
                    aria-labelledby="header-2"
                  >
                    <div className="list-group list-custom-small">
                      <button
                        className="dropdown btn btn-link px-2 py-2"
                        onClick={e => {
                          setMenu2("Parlay", "Single");
                        }}
                      >
                        {t("Single")}
                      </button>
                      <button
                        className="dropdown btn btn-link px-2 py-2"
                        onClick={e => {
                          setMenu2("Parlay", "Parlay");
                        }}
                      >
                        {t("Parlay")}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-1 px-0 text-right pt-2">
              <button
                onClick={() => {
                  toggleDrawer(true);
                }}
                className="btn btn-link color-white"
              >
                <i className="fas color-white fa-filter" />
              </button>
            </div>
          </div>
        </div>
        <div
          id="odds-filter"
          className={`menu menu-box-right bg-white rounded-0`}
          data-menu-active="nav-components"
          data-menu-width={300}
          style={{ display: "block", width: "300px" }}
        >
          <div className="header header-logo-center mb-4 result-drawer">
            <p className="header-title">{t("Filter")}</p>
            <div
              className="header-icon header-icon-4"
              onClick={() => {
                toggleDrawer(false);
              }}
            >
              <i className="fas fa-chevron-right" />
            </div>
          </div>

          <div className="card pt-3">
            <div className="content mb-0">
              <p className="font-weight-bold mb-0">{t("Sort By")}</p>
              <div className="row">
                <div className="col-12">
                  <div
                    className={`btn-group btn-block btn-group-sm ${
                      classes.groupButton
                    }`}
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        setSortOrder("Time");
                        setSeconds(0);
                      }}
                      className={`btn ${
                        sortOrder === "Time"
                          ? classes.activeButton
                          : classes.inactiveButton
                      }`}
                    >
                      {t("Time")}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSortOrder("");
                        setSeconds(0);
                      }}
                      className={`btn ${
                        !sortOrder
                          ? classes.activeButton
                          : classes.inactiveButton
                      }`}
                    >
                      {t("Priority")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button
                    className={`btn btn-block btn-sm m-0 text-align-center ${
                      classes.groupButton
                    } ${classes.inactiveButton}`}
                    onClick={() => {
                      setOpenLeagueFilter(true);
                    }}
                  >
                    <i className="fa fa-trophy p-1" />{" "}
                    <span className="pl-2">{t("Select League")}</span>
                  </button>
                </div>
              </div>

              <div className="row mb-0 justify-content-center">
                <div className="col-10">
                  <hr />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="d-flex mt-1">
                    <div className="pt-1">
                      <span
                        className={`${classes.inactiveButton}`}
                        data-activate="toggle-id-1"
                      >
                        <i className="fa fa-star star-fill p-1" />{" "}
                        <span>{t("Show Favorites")}</span>
                      </span>
                    </div>
                    <div className="ml-auto">
                      <div className="custom-control ios-switch highlight-switch">
                        <input
                          type="checkbox"
                          onChange={() => {
                            setOnlyFav(fav => !fav);
                            setSeconds(0);
                          }}
                          className="ios-input d-none"
                          id="toggle-id-1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="toggle-id-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modal for league filter */}
        <Modal
          open={openLeagueFilter}
          onClose={() => setOpenLeagueFilter(false)}
          aria-labelledby="league-filter-modal"
          aria-describedby="modal used for league filter"
          className={`${
            classes.leagueFilterModal
          }  animate__animated animate__slideInRight	
          `}
          id="modal-league-filter"
        >
          <div className={classes.Modal}>
            <LeagueFilter
              setOpenLeagueFilter={setOpenLeagueFilter}
              trigger={() => {
                toggleDrawer(false);
                setSeconds(0);
              }}
              getSelectedMenu={getSelectedMenu}
            />
          </div>
        </Modal>

        <Footer />

        <div className="page-title-clear" />
        <div className="page-content">
          {/* Refresh counter: {counter}, Matches: {matches.length} */}
          <TableInfo
            matches={matches}
            getSelectedMenu={getSelectedMenu}
            onlyFav={onlyFav}
            sortOrder={sortOrder}
            parlayBet={parlayBet}
            setParlayBet={setParlayBet}
            parlayModal={parlayModal}
            setParlayModal={setParlayModal}
            loadSingleMatch={loadSingleMatch}
            refreshLeague={refreshLeague}
            removeSingleMatch={removeSingleMatch}
            clearOddSelection={clearOddSelection}
            expandCollapseLeague={expandCollapseLeague}
          />
        </div>
      </ParlayProvider>

      {/* Page content ends here*/}
    </>
  );
};

export default Mobile;
