import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../config';
import '../../styles/MobileMain.css';
//import BetInfo from '../../components/main/BetInfo';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import { Modal } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import { HistoryContext } from '../../context/HistoryContext';
import ParlayInfo from '../../components/main/ParlayInfo';

import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  cardHeader: {
    background: '#86898C',
    borderRadius: '4px 4px 0 0',
  },
  borderDiv: {
    border: '0.5px solid #86898C',
  },
  cardBody: {
    boxShadow: '0px 2px 8px rgba(81, 81, 81, 0.4)',
    borderRadius: '0 0 4px 4px',
  },
  viewBtn: {
    border: '1px solid #86898C',
    color: '#86898C',
    backgroundColor: 'transparent',
  },
  reject: {
    textDecoration: 'line-through',
  },
  strong: {
    color: '#DC252F',
  },
  teams: {
    fontSize: '13px',
    color: '#18334C',
    margin: '0',
  },
  desc: {
    fontSize: '13px',
    fontWeight: 'bolder',
    color: '#323030',
    margin: '0',
  },
  compName: {
    color: '#0E2336',
    fontSize: '11px',
    fontWeight: 'bold',
    margin: '0.05rem 0',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
    margin: ' 0',
  },
  handiCap: {
    color: '#1452CC ',
    fontSize: '13px',
    fontWeight: 'bold',
    margin: ' 0',
    backgroundColor: 'transparent',
    border: 'none',
  },
  money: {
    color: '#1452CC',
    fontSize: '13px',
    fontWeight: 'bold',
    // margin: '0 ',
  },
  amount: {
    fontWeight: 'bold',
    fontSize: '13px',
    color: '#1452CC',
    margin: '0',
  },

  refresh: {
    cursor: 'pointer',
    height: '12px',
    width: '12px',
  },
  spacing: {
    padding: '0.15rem 0.75rem',
    borderBottom: '1px solid rgba(134, 137, 140, 0.7)',
    '& p': {
      margin: '0 ',
    },
  },
  waiting: {
    backgroundColor: '#f9eded',
  },

  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex2: {
    display: 'flex',
    padding: '0.15rem 0.75rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  printWrapper: {
    display: 'flex',
    '& a': {
      fontSize: '13px',
      margin: '0 0.25rem',
    },
  },
  Modal: {
    margin: '0 auto',
    width: '85vw',
    maxWidth: '480px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  division: {
    borderBottom: '1px solid #86898C',
  },
  card: {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px 5px rgba(0, 0, 0, 0.03)',
    borderRadius: '4px',
  },
  cardUpper: {
    backgroundColor: '#86898C',
    padding: '0.0rem 0.75rem',
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardLower: {
    padding: '0.15rem 0.75rem',
    borderRadius: '0 0 4px 4px ',
    '& p': {
      margin: '0 !important',
    },
  },
  heading: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  negative: {
    color: '#E91F1F',
  },
}));

const StatementHistory = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [detail, setDetail] = useState([]);
  const [pageSize, setPageSize] = useState();
  const [moreInfo, setMoreInfo] = useState(null);
  const { historyTab } = useContext(HistoryContext);
  const [, setActiveTab] = historyTab;
  // modal
  const [open, setOpen] = useState(false);

  // handle pagination
  const handlePagination = () => {
    let pageNo = 1;
    axiosInstance
      .get(`portal/detail?date=${props.match.params.date}`, {
        params: {
          pageNo: pageNo,
        },
      })
      .then((res) => {
        // console.log(res.data.content);
        setDetail(detail.concat(res.data.content));
        pageNo++;
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 412) {
          history.goBack();
        } else if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push('/');
        }
      });
  };

  useEffect(() => {
    // console.log(props);
    axiosInstance
      .get(`portal/detail?date=${props.match.params.date}`)
      .then((res) => {
        // console.log(res.data.content);
        // console.log(res.data.totalElements);
        setDetail(res.data.content);
        setPageSize(res.data.totalElements);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 412) {
          history.goBack();
        } else if (err.response && err.response.data.status === 401) {
          localStorage.removeItem('token');
          history.push('/');
        }
      });
  }, [props, history]);

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">{t('History Detail')}</span>

        <span
          className="header-icon header-icon-1"
          onClick={() => {
            setActiveTab(2);
            history.push('/mobile/statement');
          }}
        >
          <i className="fas color-white fa-arrow-left color-red-light"></i>
        </span>
      </div>
      <div className="page-title-clear" />
      <div className="page-content">
        {detail.map((hdetail, index) => (
          <React.Fragment key={index}>
            {hdetail.market === 'PARLAY' ? (
              <div className={`px-2 my-2`}>
                <div className={`card ${classes.card}`}>
                  <div
                    className={`d-flex justify-content-between px-2 py-1 font-weight-bold ${classes.cardHeader}`}
                  >
                    <p className={`mb-0 text-white`}>
                      {moment(hdetail.created).format('D/M/YY H:mm a')}
                    </p>
                    <p className={`mb-0 text-white`}>
                      {' '}
                      {hdetail.sport} {hdetail.market} / {hdetail.type}
                    </p>
                  </div>
                  <div className={`${classes.cardBody} p-2`}>
                    <p className={`text-secondary mb-0`}>{hdetail.reference}</p>
                    <div
                      className={`d-flex justify-content-between align-items-center `}
                    >
                      <p className={`text-body font-weight-bold mb-0`}>
                        {t('Mix Parlay')} @{' '}
                        <CurrencyFormat
                          value={hdetail.odds}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </p>
                      <button
                        className={`${classes.viewBtn}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setMoreInfo(hdetail);
                          setOpen(true);
                        }}
                      >
                        {t('View Detail')}
                      </button>
                    </div>
                    <div className={`${classes.borderDiv} w-100 my-1`}></div>
                    <div className={`w-50 d-flex justify-content-between `}>
                      <p className={`mb-0 text-body`}>
                        {t('Stake')}:{' '}
                        <span className={`font-weight-bold`}>
                          <CurrencyFormat
                            value={hdetail.amount}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </span>
                      </p>
                      <p className={`mb-0 text-body`}>
                        {t('Payout')}:{' '}
                        <span
                          className={`
                  text-primary font-weight-bold`}
                        >
                          <CurrencyFormat
                            value={
                              hdetail.market === 'PARLAY'
                                ? (hdetail.amount /
                                    parseInt(hdetail.type.split(' ')[2])) *
                                  hdetail.odds
                                : hdetail.payout
                            }
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </span>
                      </p>
                      {hdetail.winning != null ? (
                        <p className={`mb-0 text-body`}>
                          {t('Winning')}:{' '}
                          <span
                            className={`font-weight-bold ${
                              hdetail.winning < 0
                                ? classes.negative
                                : ' text-primary'
                            }`}
                          >
                            <CurrencyFormat
                              value={hdetail.winning}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </span>
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`px-2 my-2`}>
                <div className={`card ${classes.card}`}>
                  <div
                    className={`d-flex justify-content-between px-2 py-1 font-weight-bold ${classes.cardHeader}`}
                  >
                    <p className={`mb-0 text-white`}>
                      {moment(hdetail.created).format('D/M/YY H:mm a')}
                    </p>
                    <p className={`mb-0 text-white`}>
                      {hdetail.sport} {hdetail.market} / {hdetail.game}
                    </p>
                  </div>
                  <div className={`${classes.cardBody} p-2`}>
                    <p className={`text-secondary mb-0`}>{hdetail.reference}</p>
                    <p
                      className={`text-body font-weight-bold mb-0${
                        hdetail.status === 'REJECTED'
                          ? ' ' + classes.reject
                          : ''
                      }`}
                    >
                      {hdetail.comp}
                    </p>
                    <p
                      className={`text-body mb-0${
                        hdetail.status === 'REJECTED'
                          ? ' ' + classes.reject
                          : ''
                      }`}
                    >
                      <span
                        className={hdetail.high === 1 ? 'text-danger' : null}
                      >
                        {hdetail.home}
                      </span>{' '}
                      {t('vs')}{' '}
                      <span
                        className={hdetail.high === 2 ? 'text-danger' : null}
                      >
                        {hdetail.away}
                      </span>
                    </p>
                    {hdetail.market === 'LIVE' ? (
                      <p className={`text-danger mb-0`}>
                        ({t('Running')} {hdetail.homeGoal}-{hdetail.awayGoal})
                      </p>
                    ) : null}
                    <p
                      className={`text-body text-body font-weight-bold mb-0${
                        hdetail.status === 'REJECTED'
                          ? ' ' + classes.reject
                          : ''
                      }`}
                    >
                      {hdetail.desc}{' '}
                      {hdetail.handicap !== '' ? (
                        <span className={`text-primary mt-2`}>
                          ({hdetail.handicap})
                        </span>
                      ) : null}{' '}
                      @{' '}
                      <CurrencyFormat
                        value={hdetail.odds}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={`text-primary font-weight-bold`}
                      />
                    </p>
                    {hdetail.status === 'REJECTED' ? (
                      <p className={`text-danger mb-0`}>
                        {t('Reject')}{' '}
                        {hdetail.rejectReason
                          ? '(' + hdetail.rejectReason + ')'
                          : null}
                      </p>
                    ) : null}
                    <div className={`${classes.borderDiv} w-100 my-1`}></div>
                    <div className={`w-50 d-flex justify-content-between `}>
                      <p className={`mb-0 text-body`}>
                        {t('Stake')}:{' '}
                        <span className={`font-weight-bold`}>
                          {' '}
                          <CurrencyFormat
                            value={hdetail.amount}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </span>
                      </p>
                      <p className={`mb-0 text-body`}>
                        {t('Payout')}:{' '}
                        <span className={`text-primary font-weight-bold`}>
                          <CurrencyFormat
                            value={
                              hdetail.market === 'PARLAY'
                                ? (hdetail.amount /
                                    parseInt(hdetail.type.split(' ')[2])) *
                                  hdetail.odds
                                : hdetail.payout
                            }
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </span>
                      </p>
                      {hdetail.winning != null ? (
                        <p className={`mb-0 text-body`}>
                          {t('Winning')}:{' '}
                          <span
                            className={`font-weight-bold ${
                              hdetail.winning < 0
                                ? classes.negative
                                : ' text-primary'
                            }`}
                          >
                            <CurrencyFormat
                              value={hdetail.winning}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </span>
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
        {/* for pagination */}
        {detail.length < pageSize ? (
          <>
            <div className="card">
              <button
                className="btn btn-m btn-full mb-3 ml-3 mr-3 rounded-xs btn-green"
                onClick={() => {
                  handlePagination();
                }}
              >
                {t('Load More ...')}
              </button>
              <p />
            </div>
          </>
        ) : null}
      </div>
      {/* Page content ends here*/}
      {/* Modal */}
      {moreInfo !== null ? (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modalStyle}
        >
          <ParlayInfo moreInfo={moreInfo} classes={classes} />
        </Modal>
      ) : null}
    </>
  );
};

export default StatementHistory;
