import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../config";
import { useHistory } from "react-router-dom";
import "../styles/Mobile.css";
import MoreGame from "../components/MoreGame";

const Game = ({
  User,
  setGameModal,
  id,
  handleOdd,
  parlayBet,
  refreshParlaySelection,
  parlayFlag,
  setParlayModal
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  // const [activeTab, setActiveTab] = useState('main');
  const [match, setMatch] = useState();
  const [odds, setOdds] = useState([]);
  // const [id, setId] = useState('3dbbd50e-382b-448a-a413-20c9b534e73b');
  // const [id, setId] = useState('2544d099-c45f-4ba8-927a-a76dfdf7b1bd'); // 316e693e-550f-44bc-87d7-00ed99a3f7d9
  // const [id, setId] = useState('316e693e-550f-44bc-87d7-00ed99a3f7d9');

  const getGameType = code => {
    if ("AHFT" === code) return t("FT Handicap");
    else if ("AHFH" === code) return t("HT Handicap");
    else if ("OUFT" === code) return t("FT Over/Under");
    else if ("OUFH" === code) return t("HT Over/Under");
    else if ("OE" === code || "OEFH" === code) return t("Odd/Even");
    else return code;
  };

  useEffect(
    () => {
      // console.log('use effect');
      // let id = '7243a6ef-b930-4f88-856a-4e13cf34d92c';
      let url =
        "restricted/mobile/oddHdp/match/" + id + "?parlay=" + parlayFlag;

      axiosInstance
        .get(url)
        .then(res => {
          setMatch(res.data);
          setOdds(res.data.odds);
        })
        .catch(err => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            localStorage.removeItem('token');
            history.push("/");
          }
        });
    },
    [id, history, parlayFlag]
  );

  return (
    <>
      <div className="header header-fixed header-logo-center mb-4 bg-dardred-light shadow-l">
        <span className="header-title color-white">
          {match ? match.league : null}
        </span>

        <span
          className="header-icon header-icon-1"
          onClick={() => {
            refreshParlaySelection();
            setGameModal(false);
          }}
        >
          <i className="fas color-white fa-arrow-left color-red-light" />
        </span>
      </div>

      <div className="page-title-clear" />

      {/* <div className="second-header header-logo-right" id="sports">
          <ul className="list-unstyled submenulist">
            <li>
              {activeTab === 'main' ? (
                <span>Main</span>
              ) : (
                <button
                  onClick={() => {
                    setActiveTab('main');
                  }}
                >
                  Main
                </button>
              )}
            </li>
            <li>
              {activeTab === 'full-time' ? (
                <span>Full Time</span>
              ) : (
                <button
                  onClick={() => {
                    setActiveTab('full-time');
                  }}
                >
                  Full Time
                </button>
              )}
            </li>
            <li>
              {activeTab === 'half-time' ? (
                <span>Half Time</span>
              ) : (
                <button
                  onClick={() => {
                    setActiveTab('half-time');
                  }}
                >
                  Half Time
                </button>
              )}
            </li>
            <li>
              {activeTab === 'corner' ? (
                <span>Corner</span>
              ) : (
                <button
                  onClick={() => {
                    setActiveTab('corner');
                  }}
                >
                 Corner
                </button>
              )}
            </li>
          </ul>
        </div> */}

      {odds ? (
        <div className="page-content container detail-section">
          {odds.map((odd, index) => (
            <React.Fragment key={index}>
              <div className="card card-style rounded mx-0 mb-3 mt-3">
                <div className="card-header">{getGameType(odd.gameType)}</div>
                <div className="card-body pb-2 pt-1">
                  {odd.gameType === "AHFT" || odd.gameType === "AHFH" ? (
                    <MoreGame
                      odd={odd}
                      idxPrice={2}
                      idxHdp={5}
                      handleOdd={handleOdd}
                      parlayBet={parlayBet}
                    />
                  ) : odd.gameType === "OUFT" || odd.gameType === "OUFH" ? (
                    <MoreGame
                      odd={odd}
                      idxPrice={2}
                      prefixHdp={["o", "u"]}
                      idxHdp={5}
                      handleOdd={handleOdd}
                      parlayBet={parlayBet}
                    />
                  ) : odd.gameType === "1X2" || odd.gameType === "1X2FH" ? (
                    <MoreGame
                      odd={odd}
                      idxPrice={2}
                      prefixHdp={["1", "2", "X"]}
                      handleOdd={handleOdd}
                      parlayBet={parlayBet}
                    />
                  ) : odd.gameType === "OE" || odd.gameType === "OEFH" ? (
                    <MoreGame
                      odd={odd}
                      idxPrice={2}
                      prefixHdp={["odd", "even"]}
                      handleOdd={handleOdd}
                      parlayBet={parlayBet}
                    />
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : null}

      {/* the parlay bubble */}
      {parlayFlag === "Y" &&
      parlayBet !== undefined &&
      parlayBet.parlays.length !== 0 ? (
        <div
          className={
            parlayBet.parlays.length !== 0
              ? `ticket d-flex justify-content-center align-items-center glow-circle `
              : `ticket d-flex justify-content-center align-items-center`
          }
          onClick={() => {
            refreshParlaySelection();
            setGameModal(false);
            setParlayModal(true);
          }}
        >
          <i className="fas fa-ticket-alt text-success fa-2x" />
          {parlayBet.parlays.length !== 0 ? (
            <div className="match-length d-flex justify-content-center align-items-center">
              <p className="m-0 text-white text-center">
                {parlayBet.parlays.length}
              </p>
            </div>
          ) : null}
        </div>
      ) : null}

      {/* Page content ends here*/}
    </>
  );
};

export default Game;
