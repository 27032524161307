import React from "react";
import { useTranslation } from "react-i18next";

const LoadingPage = () => {
  const { t } = useTranslation();
  return (
    <div className="card-center text-center">
      <div className="spinner-border color-red-dark" role="status">
        <span className="sr-only">{t("Loading")}...</span>
      </div>
    </div>
  );
};

export default LoadingPage;
